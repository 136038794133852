import React, { Fragment, useState, useEffect, useCallback } from 'react';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../components/layouts/MainLayout';
import CheckIn from '../components/views/CheckIn';
import { bookingListAction } from '../stores/actions';
// import * as api from '../api';

const CheckInContainer = ({
  history,
}) => {
  const [ userName, setUserName ] = useState('');
  const [ phoneNumber, setPhoneNumber ] = useState('');
  const [ verificationCode, setVerificationCode ] = useState('');
  // const [ checkinRequestParams, setCheckinRequestParams ] = useState({
  //   name: '',
  //   phoneNumber: '',
  // });
  // const { name, phoneNumber, isSkipBookingAuth } = useSelector(state => state.userConfig);
  const { bookingItem } = useSelector(state => state.bookingList);
  const dispatch = useDispatch();
  const { Kakao } = window;

  const loginWithKakao = useCallback(() => {
    try {
      Kakao.Auth.login({
        success: function(response) {
          getUserInfoWithKakao();
        },
        fail: function(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserInfoWithKakao = useCallback(async () => {
    try {
      Kakao.API.request({
        url: '/v2/user/me',
        success: function(response) {
          const parsedPhoneNumber = `0${response.kakao_account.phone_number.split(' ')[1].replace(/-/gi, '')}`;
          dispatch(bookingListAction.setUserInfo({
            phoneNumber: parsedPhoneNumber,
            email: response.kakao_account.email,
          }));
          history.push('/checkin/payment');
          // history.push('/', {status: 'checked_in'});
          // history.push('/payment');
        },
        fail: function(error) {
          console.log(error);
        },
      });
    } catch (error) {
      console.log(error);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIn = useCallback(async () => {
    try {
      loginWithKakao();
      // history.push('/', {status: 'checked_in'});
    } catch (error) {
      console.log(error);
    }
  }, [loginWithKakao]);

  // useEffect(() => {
  //   dispatch(userConfigAction.setBookingAuthRequest({
  //     name: '',
  //     phoneNumber: '',
  //   }));
  // }, [dispatch, history, isSkipBookingAuth]);

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== 'RR') history.push(`/?rsvnNo=${bookingItem.rsvnNo}&rsvnSeqNo=${bookingItem.rsvnSeqNo}`);
  }, [bookingItem.rsvnNo, bookingItem.rsvnSeqNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_kakao_auth',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <CheckIn
            userName={userName}
            setUserName={setUserName}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            verificationCode={verificationCode}
            setVerificationCode={setVerificationCode}
            // setBookingListInputItem={(state) => dispatch(userConfigAction.setBookingAuthRequest(state))}
            // checkinRequestParams={checkinRequestParams}
            // setCheckinRequestParams={setCheckinRequestParams}
            checkIn={checkIn}
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default CheckInContainer;