import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_SANHA_BACKEND_BASEURL;
const apiKey = '$2a$12$8Ni1lPK8.ZfYit98HO3Fm.0n1cmJvz6gZt9bmsIux9QAo.wf8G/IC';
const vendorId = 'NOMADSOFT';
const langTypeCode = 'KOR';
const companyId = 'HDCRESORT';
const userId = 'DOWHAT';

// config
const request = axios.create({
  baseURL,
  timeout: 30000,
});

const querystring = (query) => {
  return {
    params: query,
  };
};

const headers = (header) => {
  return {
    headers: {
      'API-KEY': apiKey,
      'VENDOR_ID': vendorId,
      'Content-Type': 'application/json',
      ...header && header,
    }
  };
};

export const checkIn = ({bsnsCode, propertyNo, folioNo}) => {
  return request.post('/dowhat/v1/checkIn',
    {
      bsnsCode,
      companyId,
      folioNo,
      langTypeCode,
      propertyNo,
      userId,
    },
    headers(),
  );
};


export const checkIn2 = ({bsnsCode, propertyNo, folioNo}) => {
  return request.post('/dowhat/v1/checkIn',
    {
      data: {
        bsnsCode,
        companyId,
        folioNo,
        langTypeCode,
        propertyNo,
        userId,
      }
    },
    headers(),
  );
};

export const checkOut = ({bsnsCode, propertyNo, folioNo}) => {
  return request.post('/dowhat/v1/checkOut',
    {
      bsnsCode,
      companyId,
      folioNo,
      langTypeCode,
      propertyNo,
      userId,
    },
    headers(),
  );
};