import React, { Fragment, useState, useEffect, useCallback } from 'react';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useSelector } from 'react-redux';
import moment from 'moment';
import queryString from 'query-string';
import MainLayout from '../components/layouts/MainLayout';
import PaymentLoading from '../components/views/PaymentLoading';
import ModalError from '../components/ModalError';
import PaymentError from '../components/PaymentError';
import Spinner from '../components/Spinner';
import * as api from '../api/sanha';

const CheckOutPaymentResponseContainer = ({
  history,
  match,
}) => {
  const urlQueryStringParams = queryString.parse(history.location.search);
  const { bookingItem } = useSelector(state => state.bookingList);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isOpenPaymentError, setIsOpenPaymentError ] = useState(false);
  const [ paymentErrorMessage, setPaymentErrorMessage ] = useState('');
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openPaymentError = () => {
    setIsOpenPaymentError(true);
  };

  const closePaymentError = () => {
    history.replace('/checkout/payment');
    setIsOpenPaymentError(false);
  };

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    history.replace('/');
  };

  const registCheckOutPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.ResultCode !== '3001') {
        setPaymentErrorMessage(urlQueryStringParams ? `${urlQueryStringParams.ResultCode}, ${urlQueryStringParams.ResultMsg}` : '');
        openPaymentError();
      } else {
        setIsLoading(true);
        const { data: registCheckOutPaymentResponse } = await api.registCheckOutPayment({
          folioNo: bookingItem.folioNo,
          trnsNo: urlQueryStringParams.Tid,
          intApprTypeCode: 'P',
          intPayStatusCode: 11,
          payFnshDate: '',
          successYn: urlQueryStringParams.ResultCode === '3001' ? 'Y' : 'N',
          trnsDate: moment().format('YYYYMMDD'),
          trnsHHMMSS: moment().format('HHmmss'),
          trnsAmt: urlQueryStringParams.Amt,
          cardMonthCnt: urlQueryStringParams.CardQuota || '',
          cardApprNo: urlQueryStringParams.AuthCode || '',
          cardName: urlQueryStringParams.AppCardName || '',
          cardIssueCode: urlQueryStringParams.AppCardCode || '',
          cardPurcCode: urlQueryStringParams.AcquCardCode || '',
          trnsBankCode: '',
          acctNo: '',
          depositor: urlQueryStringParams.BuyerName || '',
          cashRcptTypeCode: 0,
          cashRcptApptNo: '',
          cashRcptCxlApptNo: '',
          responseCode: urlQueryStringParams.ResultCode || '',
          message1: urlQueryStringParams.ResultMsg || '',
          message2: '',
          dpsitPayDivCode: '01',
          userId: 'KEYLESS',
          userIp: '127.0.0.0',
          payMgtNo: '',
          dpsitPayNo: '',
          outYN: '',
          outMsg: '',
        });
        if (registCheckOutPaymentResponse.userStatus.code !== 2000) {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_fail_regist_checkout_payment',
            label: `${registCheckOutPaymentResponse.userStatus.code}_${registCheckOutPaymentResponse.userStatus.message}`,
          });
          setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
          throw new Error('프론트로 문의 바랍니다.');
        } else {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_ok_regist_checkout_payment',
          });
          const { data: checkOutResponse } = await api.checkOut({
            folioNo: bookingItem.folioNo,
            earlyCheckoutYN: 'N',
          });
          if (checkOutResponse.userStatus.code !== 2000) {
            GoogleAnalytics.customEvent({
              category: 'api_response',
              action: 'api_response_fail_checkout_request',
              label: `${checkOutResponse.userStatus.code}_${checkOutResponse.userStatus.message}`,
            });
            setModalErrorMessage('결제는 성공하였으나, 체크아웃에 실패 하였습니다.');
            throw new Error('프론트로 문의 바랍니다.');
          } else {
            GoogleAnalytics.customEvent({
              category: 'api_response',
              action: 'api_response_ok_checkout_request',
            });
            history.replace('/checkout/payment/success');
          }
        }
      }
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    registCheckOutPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkout_payment_result',
      label: urlQueryStringParams ? `${urlQueryStringParams.ResultCode}_${urlQueryStringParams.ResultMsg}}` : 'null',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <PaymentLoading
            history={history}
          />
        )}
      />
      <PaymentError
        isOpen={isOpenPaymentError}
        message={paymentErrorMessage}
        onClose={closePaymentError}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default CheckOutPaymentResponseContainer;