import React, { Fragment, useState, useCallback } from 'react';
import moment from 'moment';
import * as GoogleAnalytics from '../../lib/google-analytics';
import LocationService from './LocationService';
import LocationServiceGuideIOS from './LocationServiceGuideIOS';

const InitialBooking = ({
  bookingItem,
  listBooking,
  setModalErrorMessage,
  setModalErrorSubMessage,
  openModalError,
  history,
}) => {
  const [ isOpenModalLocationService, setIsOpenModalLocationService ] = useState(false);
  const [ isOpenModalLocationServiceGuideIOS, setIsOpenModalLocationServiceGuideIOS ] = useState(false);

  const isIPhone = () => {
    const iPhone = 'iPhone';
    if (navigator.userAgent.indexOf(iPhone) < 0) return false;
    else return true;
  };

  const openModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(true);
  }, []);

  const closeModalLocationService = useCallback(() => {
    setIsOpenModalLocationService(false);
  }, []);

  const openModalLocationServiceGuideIOS = useCallback(() => {
    setIsOpenModalLocationServiceGuideIOS(true);
  }, []);

  const closeModalLocationServiceGuideIOS = useCallback(() => {
    setIsOpenModalLocationServiceGuideIOS(false);
    setIsOpenModalLocationService(true);
  }, []);

  const validateCheckIn = (checkinDate) => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_request',
    });
    const availableCheckinDate = `${checkinDate}T1500`;
    if (moment(availableCheckinDate) < moment()) {
      if (isIPhone()) openModalLocationServiceGuideIOS();
      else openModalLocationService();
    }
    else {
      setModalErrorMessage('체크인을 할 수 없습니다.');
      setModalErrorSubMessage('체크인은 3시 이후 부터 가능 합니다.');
      openModalError();
    }
  };

  const periodFormatter = (arrvDate, deptDate) => {
    return `${moment(arrvDate).format('YYYY.MM.DD')} ~ ${moment(deptDate).format('YYYY.MM.DD')}`
  };

  return (
    <Fragment>
      <div className='initial-booking-welcome-message-container'>
        <span className='initial-booking-welcome-message'>
          환영합니다. 고객님,<br/>
          체크인을 진행하겠습니다.
        </span>
      </div>
      {bookingItem && bookingItem.rsvnNo ? (
        <Fragment>
          <div className='banner-img1'></div>
          <div className='banner-img2'></div>
          <div className='banner-img3'></div>
          <div className='initial-booking-card-container'>
            {/* <div className='hotel-img-container'></div> */}
            <span className='booking-period'>
              {periodFormatter(bookingItem.arrvDate, bookingItem.deptDate)}
            </span>
            <span className='hotel-name'>
              {bookingItem.propertyName}
            </span>
            <div className='user-info-container'>
              <img className='user-icon' src='../../assets/images/profile.png' alt='' />
              <span className='user-name'>
                {bookingItem.guestName}
              </span>
              <span className='booking-number'>
                {bookingItem.rsvnNo}
              </span>
            </div>
            <div className='check-in-button' onClick={() => validateCheckIn(bookingItem.arrvDate)}>
              <span className='check-in-button-text'>
                체크인
              </span>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className='banner-img1'></div>
          <div className='banner-img2'></div>
          <div className='banner-img3'></div>
          <div className='initial-booking-card-container'>
            {/* <div className='hotel-img-container'></div> */}
            <span className='no-booking'>예약을 찾을 수 없습니다.</span>
          </div>
        </Fragment>
      )}
      <LocationService
        isOpen={isOpenModalLocationService}
        onClose={closeModalLocationService}
        history={history}
      />
      <LocationServiceGuideIOS
        isOpen={isOpenModalLocationServiceGuideIOS}
        onClose={closeModalLocationServiceGuideIOS}
        history={history}
      />
    </Fragment>
  );
};

export default InitialBooking;