import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as GoogleAnalytics from '../lib/google-analytics';
import MainLayout from '../components/layouts/MainLayout';
import TermsOfUseList from '../components/views/TermsOfUseList';

const TermsOfUseListContainer = ({
  history,
  match,
}) => {
  const { bookingItem } = useSelector(state => state.bookingList);

  useEffect(() => {
    if (bookingItem.rsvnStatusCode !== 'RR') history.push(`/?rsvnNo=${bookingItem.rsvnNo}&rsvnSeqNo=${bookingItem.rsvnSeqNo}`);
  }, [bookingItem.rsvnNo, bookingItem.rsvnSeqNo, bookingItem.rsvnStatusCode]);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_terms_of_use',
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <TermsOfUseList
            history={history}
          />
        )}
      />
    </Fragment>
  );
};

export default TermsOfUseListContainer;