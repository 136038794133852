import React, { Fragment, useEffect } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import Modal from '../Modal';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const MobilekeyMenual = ({
  isOpen,
  onClose,
}) => {
  const moveToApp = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_mobilekey_download',
    });
    const appLink = 'https://ovalmms.page.link/key';
    window.open(appLink);
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_mobilekey_menual',
    });
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={true}
        onClose={onClose}
        Content={(
          <Fragment>
            <Slider
              dots={true}
              infinite={false}
              autoplaySpeed={400}
            >
              <div>
                <img className='mobilekey-menual w-60 m-auto mt-15 mb-20' src='../assets/images/app-download.png' alt='' />
                <span className='mobilekey-menual-title'>
                  모바일 키 사용방법
                </span>
                <span className='mobilekey-menual-description'>
                  모바일 키 앱 다운받기
                </span>
                <div className='app-link-container' onClick={moveToApp}>
                  <img className='download mt-3 mr-6' src='../assets/images/download.png' alt='' />
                  <span className='app-name'>
                    OVAL KEY
                  </span>
                  <span className='download-label'>
                    다운받기
                  </span>
                </div>
              </div>
              <div>
                <img className='mobilekey-menual mt-15 mb-20' src='../assets/images/mobilekey-input.png' alt='' />
                <span className='mobilekey-menual-title'>
                  모바일 키 사용방법
                </span>
                <span className='mobilekey-menual-description h-60 mb-32'>
                  모바일 키 4자리를<br/>
                  인증코드에 입력해주세요
                </span>
              </div>
              <div>
                <img className='mobilekey-menual mt-15 mb-20' src='../assets/images/doorlock-touch.png' alt='' />
                <span className='mobilekey-menual-title'>
                  모바일 키 사용방법
                </span>
                <span className='mobilekey-menual-description h-60'>
                  모바일 앱을 실행하신 후<br/>
                  손으로 도어락을 터치해주세요
                </span>
                <span className='mobilekey-setting-description'>
                  GPS와 블루투스가 켜져있는지 확인해주세요
                </span>
              </div>
            </Slider>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default MobilekeyMenual;