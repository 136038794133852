import React, { Fragment, useState, useEffect, useCallback } from "react";
import * as GoogleAnalytics from "../lib/google-analytics";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import moment from "moment";
import queryString from "query-string";
import MainLayout from "../components/layouts/MainLayout";
import RoomSelect from "../components/views/RoomSelect";
import ModalError from "../components/ModalError";
import PaymentError from "../components/PaymentError";
import Spinner from "../components/Spinner";
import * as facility from "../resource/facility";
import { bookingListAction } from "../stores/actions";
import * as api from "../api/sanha";
import * as Sentry from "@sentry/react";
import * as dowhatApi from "../api/dowhat";

const RoomSelectContainer = ({ history, match }) => {
  const urlQueryStringParams = queryString.parse(history.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRoomList, setTotalRoomList] = useState([]);
  const [selectedPropertyList, setSelectedPropertyList] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState({});
  const [selectedZoneTypeList, setSelectedZoneTypeList] = useState([]);
  const [selectedZoneType, setSelectedZoneType] = useState({});
  const [selectedSubZoneTypeList, setSelectedSubZoneTypeList] = useState([]);
  const [selectedSubZoneType, setSelectedSubZoneType] = useState({});
  const [selectedFloorList, setSelectedFloorList] = useState([]);
  const [selectedFloor, setSelectedFloor] = useState({});
  const [selectedRoomList, setSelectedRoomList] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [isOpenPaymentError, setIsOpenPaymentError] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [modalErrorMessage, setModalErrorMessage] = useState("");
  const [modalErrorSubMessage, setModalErrorSubMessage] = useState("");
  const { bookingItem, queryStringParams } = useSelector(
    (state) => state.bookingList
  );
  const dispatch = useDispatch();

  const openPaymentError = () => {
    setIsOpenPaymentError(true);
  };

  const closePaymentError = () => {
    history.replace("/checkin/payment");
    setIsOpenPaymentError(false);
  };

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    // history.replace('/');
  };

  const subZoneTypeFormatter = (roomNo) => {
    return ["5", "6", "7"].includes(roomNo.slice(4, 5)) ? "east" : "west";
  };

  // const confirmCheckInPayment = useCallback(async () => {
  //   try {
  //     const { data: response } = await api.confirmCheckInPayment({
  //       rsvnNo: bookingItem.rsvnNo,
  //       rsvnSeqNo: bookingItem.rsvnSeqNo,
  //     });
  //     if (response.userStatus.code !== 2000) throw new Error(response.userStatus.message);
  //     if (response.resultData.payYn === 'N') history.push('/checkin/payment');
  //     else await listAvailableRoom();
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const registCheckInPayment = useCallback(async () => {
    try {
      if (!urlQueryStringParams || urlQueryStringParams.ResultCode !== "3001") {
        Sentry.captureMessage(
          `registCheckInPayment_Error : 결제 요청에 실패 하였습니다. (예약번호:${bookingItem.rsvnNo})`
        );
        setPaymentErrorMessage(
          urlQueryStringParams
            ? `${urlQueryStringParams.ResultCode}, ${urlQueryStringParams.ResultMsg}`
            : ""
        );
        openPaymentError();
      } else {
        console.log(urlQueryStringParams);
        Sentry.captureMessage(
          `payment_room_page_success : 결제 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`
        );
        const { data: response } = await api.registCheckInPayment({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: bookingItem.rsvnSeqNo,
          trnsNo: urlQueryStringParams.Tid,
          intApprTypeCode: "P",
          intPayStatusCode: 11,
          payFnshDate: "",
          successYn: urlQueryStringParams.ResultCode === "3001" ? "Y" : "N",
          trnsDate: moment().format("YYYYMMDD"),
          trnsHHMMSS: moment().format("HHmmss"),
          trnsAmt: urlQueryStringParams.Amt,
          cardMonthCnt: urlQueryStringParams.CardQuota || "",
          cardApprNo: urlQueryStringParams.AuthCode || "",
          cardName: urlQueryStringParams.AppCardName || "",
          cardIssueCode: urlQueryStringParams.AppCardCode || "",
          cardPurcCode: urlQueryStringParams.AcquCardCode || "",
          trnsBankCode: "",
          acctNo: "",
          depositor: urlQueryStringParams.BuyerName || "",
          cashRcptTypeCode: 0,
          cashRcptApptNo: "",
          cashRcptCxlApptNo: "",
          responseCode: urlQueryStringParams.ResultCode || "",
          message1: urlQueryStringParams.ResultMsg || "",
          message2: "",
          dpsitPayDivCode: "01",
          userId: "KEYLESS",
          userIp: "127.0.0.0",
          payMgtNo: "",
          dpsitPayNo: "",
          outYN: "",
          outMsg: "",
        });
        if (response.userStatus.code !== 2000) {
          Sentry.captureMessage(
            `registCheckInPayment_response_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`
          );
          GoogleAnalytics.customEvent({
            category: "api_response",
            action: "api_response_fail_regist_checkin_payment",
            label: `${response.userStatus.code}_${response.userStatus.message}`,
          });
          setModalErrorMessage(
            "결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다."
          );
          throw new Error("프론트로 문의 바랍니다.");
        } else {
          dispatch(
            bookingListAction.setPaymentInfo({
              rsvnNo: bookingItem.rsvnNo,
              isPaid: true,
            })
          );
          dispatch(bookingListAction.setBookingQueryStringParams("")); //결제 내역이 등록되었으면, 초기화
          if (bookingItem.roomNo) {
            GoogleAnalytics.customEvent({
              category: "api_response",
              action: "api_response_ok_regist_checkin_payment",
              label: `${response.userStatus.code}_${bookingItem.roomNo}`,
            });
            await checkIn();
          } else {
            GoogleAnalytics.customEvent({
              category: "api_response",
              action: "api_response_ok_regist_checkin_payment",
            });
            await listAvailableRoom(); // 위 예약 결제 내역 등록 api 완료되면 원복
          }
          history.replace("/room");
        }
      }
    } catch (error) {
      Sentry.captureMessage(
        `registCheckInPayment_response2_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      setModalErrorSubMessage(error.message);
      openModalError();
    }
    // } finally {
    //   await listAvailableRoom();        // 위 예약 결제 내역 등록 api 완료되면 원복
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listAvailableRoom = useCallback(async () => {
    try {
      setIsLoading(true);
      const { data: response } = await api.listAvailableRoom({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
      });
      if (
        response.userStatus.code !== 2000 &&
        (!response.resultData || !response.resultData.length)
      ) {
        Sentry.captureMessage(
          `listAvailableRoom_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo})`
        );
        setModalErrorMessage("사용 가능 객실 조회에 실패 하였습니다.");
        throw new Error(
          `${response.userStatus.code}, ${response.userStatus.message}`
        );
      }
      if (response.resultData && response.resultData.length) {
        let pendingTotalRoomList = response.resultData;
        let pendingPropertyList = facility.property;
        let pendingProperty = {
          propertyName: pendingTotalRoomList[0].propertyName,
        };
        let pendingZoneTypeList =
          facility.condo[pendingTotalRoomList[0].propertyName];
        let pendingZoneType = {
          zoneTypeName: pendingTotalRoomList[0].zoneTypeName,
        };
        let pendingSubZoneTypeList = ["A동", "B동"].includes(
          pendingTotalRoomList[0].zoneTypeName
        )
          ? facility.subZoneType[pendingTotalRoomList[0].zoneTypeName]
          : [];
        let pendingSubZoneType = ["A동", "B동"].includes(
          pendingTotalRoomList[0].zoneTypeName
        )
          ? {
              subZoneTypeCode: subZoneTypeFormatter(
                pendingTotalRoomList[0].roomNo
              ),
            }
          : {};
        let pendingFloorList =
          facility.floor[pendingTotalRoomList[0].propertyName][
            pendingTotalRoomList[0].zoneTypeName
          ];
        let pendingFloor = { floorName: pendingTotalRoomList[0].floorName };
        let pendingRoomList =
          facility.room[pendingTotalRoomList[0].propertyName][
            pendingTotalRoomList[0].zoneTypeName
          ][pendingTotalRoomList[0].floorName];
        let pendingRoom = { roomNo: pendingTotalRoomList[0].roomNo };

        pendingTotalRoomList.map((availableRoom) =>
          pendingPropertyList.map((property) => {
            if (property.propertyName === availableRoom.propertyName)
              property.status = "available";
            if (property.propertyName === pendingProperty.propertyName)
              pendingProperty = property;
            return property;
          })
        );
        pendingTotalRoomList.map((availableRoom) =>
          availableRoom.propertyName === pendingProperty.propertyName
            ? pendingZoneTypeList.map((condo) => {
                if (condo.zoneTypeName === availableRoom.zoneTypeName)
                  condo.status = "available";
                if (condo.zoneTypeName === pendingZoneType.zoneTypeName)
                  pendingZoneType = condo;
                return condo;
              })
            : ""
        );
        pendingTotalRoomList.map((availableRoom) =>
          availableRoom.propertyName === pendingProperty.propertyName &&
          availableRoom.zoneTypeName === pendingZoneType.zoneTypeName
            ? pendingSubZoneTypeList.map((subZoneType) => {
                if (
                  subZoneType.subZoneTypeCode ===
                  subZoneTypeFormatter(availableRoom.roomNo)
                ) {
                  availableRoom.subZoneTypeCode = subZoneType.subZoneTypeCode;
                  subZoneType.status = "available";
                }
                if (
                  subZoneType.subZoneTypeCode ===
                  pendingSubZoneType.subZoneTypeCode
                )
                  pendingSubZoneType = subZoneType;
                return subZoneType;
              })
            : ""
        );
        pendingTotalRoomList.map((availableRoom) =>
          availableRoom.propertyName === pendingProperty.propertyName &&
          availableRoom.zoneTypeName === pendingZoneType.zoneTypeName
            ? pendingFloorList.map((floor) => {
                if (
                  !pendingSubZoneType.subZoneTypeCode &&
                  !floor.subZoneTypeCode &&
                  floor.floorName === availableRoom.floorName
                )
                  floor.status = "available";
                if (
                  pendingSubZoneType.subZoneTypeCode ===
                    availableRoom.subZoneTypeCode &&
                  floor.floorName === availableRoom.floorName
                )
                  floor.status = "available";
                if (floor.floorName === pendingFloor.floorName)
                  pendingFloor = floor;
                return floor;
              })
            : ""
        );
        pendingTotalRoomList.map((availableRoom) =>
          availableRoom.propertyName === pendingProperty.propertyName &&
          availableRoom.zoneTypeName === pendingZoneType.zoneTypeName &&
          availableRoom.floorName === pendingFloor.floorName
            ? pendingRoomList.map((room) => {
                if (room.roomNo === availableRoom.roomNo) {
                  room.status = "available";
                  room.propertyName = availableRoom.propertyName;
                  room.zoneTypeName = availableRoom.zoneTypeName;
                  room.floorName = availableRoom.floorName;
                }
                if (room.roomNo === pendingRoom.roomNo) pendingRoom = room;
                return room;
              })
            : ""
        );
        setSelectedPropertyList(pendingPropertyList);
        setSelectedProperty(pendingProperty);
        setSelectedZoneTypeList(pendingZoneTypeList);
        setSelectedZoneType(pendingZoneType);
        setSelectedSubZoneTypeList(pendingSubZoneTypeList);
        setSelectedSubZoneType(pendingSubZoneType);
        setSelectedFloorList(pendingFloorList);
        setSelectedFloor(pendingFloor);
        setSelectedRoomList(pendingRoomList);
        setSelectedRoom(pendingRoom);
        setTotalRoomList(pendingTotalRoomList);
      }
    } catch (error) {
      Sentry.captureMessage(
        `listAvailableRoom_response_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`
      );
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkIn = async () => {
    try {
      setIsLoading(true);
      if (!bookingItem.rsvnNo || !bookingItem.rsvnSeqNo)
        throw new Error("체크인을 할 수 없습니다.");
      const { data: response } = await api.checkIn({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
        roomNo: bookingItem.roomNo || selectedRoom.roomNo,
      });
      if (response.userStatus.code !== 2000) {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_fail_checkin_request",
          label: `${response.userStatus.code}_${response.userStatus.message}`,
        });
        Sentry.captureMessage(
          `checkIn_Error : ${response.code}: ${response.message} (예약번호:${bookingItem.rsvnNo} : ${bookingItem.roomNo} : ${selectedRoom.roomNo})`
        );
        setModalErrorMessage("체크인에 실패 하였습니다.");
        throw new Error(
          `${response.userStatus.code}, ${response.userStatus.message}`
        );
      } else {
        GoogleAnalytics.customEvent({
          category: "api_response",
          action: "api_response_ok_checkin_request",
        });

        Sentry.captureMessage(
          `checkIn_success : 체크인 완료 (예약번호:${bookingItem.rsvnNo} || ${bookingItem.caclPayCode})`
        );
        dispatch(bookingListAction.setBookingQueryStringParams("")); //체크인 완료 시 QueryString 초기화
        history.replace(
          `/?rsvnNo=${bookingItem.rsvnNo}&rsvnSeqNo=${bookingItem.rsvnSeqNo}`
        ); // 위 체크인 api 완료되면 원복
      }
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const changeProperty = (property) => {
    try {
      if (
        property.status !== "available" ||
        selectedProperty.propertyName === property.propertyName
      )
        return;
      setIsLoading(true);
      let pendingAvailableRoom = _.find(totalRoomList, {
        propertyName: property.propertyName,
      });
      let pendingZoneTypeList =
        facility.condo[pendingAvailableRoom.propertyName];
      let pendingZoneType = { zoneTypeName: pendingAvailableRoom.zoneTypeName };
      let pendingSubZoneTypeList = ["A동", "B동"].includes(
        pendingAvailableRoom.zoneTypeName
      )
        ? facility.subZoneType[pendingAvailableRoom.zoneTypeName]
        : [];
      let pendingSubZoneType = ["A동", "B동"].includes(
        pendingAvailableRoom.zoneTypeName
      )
        ? { subZoneTypeCode: subZoneTypeFormatter(pendingAvailableRoom.roomNo) }
        : {};
      let pendingFloorList =
        facility.floor[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ];
      let pendingFloor = { floorName: pendingAvailableRoom.floorName };
      let pendingRoomList =
        facility.room[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ][pendingAvailableRoom.floorName];
      let pendingRoom = { roomNo: pendingAvailableRoom.roomNo };

      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === property.propertyName
          ? pendingZoneTypeList.map((condo) => {
              if (condo.zoneTypeName === availableRoom.zoneTypeName)
                condo.status = "available";
              if (condo.zoneTypeName === pendingZoneType.zoneTypeName)
                pendingZoneType = condo;
              return condo;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === property.propertyName &&
        availableRoom.zoneTypeName === pendingZoneType.zoneTypeName
          ? pendingSubZoneTypeList.map((subZoneType) => {
              if (
                subZoneType.subZoneTypeCode ===
                subZoneTypeFormatter(availableRoom.roomNo)
              ) {
                availableRoom.subZoneTypeCode = subZoneType.subZoneTypeCode;
                subZoneType.status = "available";
              }
              if (
                subZoneType.subZoneTypeCode ===
                pendingSubZoneType.subZoneTypeCode
              )
                pendingSubZoneType = subZoneType;
              return subZoneType;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === property.propertyName &&
        availableRoom.zoneTypeName === pendingZoneType.zoneTypeName
          ? pendingFloorList.map((floor) => {
              if (
                !pendingSubZoneType.subZoneTypeCode &&
                !floor.subZoneTypeCode &&
                floor.floorName === availableRoom.floorName
              )
                floor.status = "available";
              if (
                pendingSubZoneType.subZoneTypeCode ===
                  availableRoom.subZoneTypeCode &&
                floor.floorName === availableRoom.floorName
              )
                floor.status = "available";
              if (floor.floorName === pendingFloor.floorName)
                pendingFloor = floor;
              return floor;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === property.propertyName &&
        availableRoom.zoneTypeName === pendingZoneType.zoneTypeName &&
        availableRoom.floorName === pendingFloor.floorName
          ? pendingRoomList.map((room) => {
              if (room.roomNo === availableRoom.roomNo) {
                room.status = "available";
                room.propertyName = availableRoom.propertyName;
                room.zoneTypeName = availableRoom.zoneTypeName;
                room.floorName = availableRoom.floorName;
              }
              if (room.roomNo === pendingRoom.roomNo) pendingRoom = room;
              return room;
            })
          : ""
      );
      setSelectedProperty(property);
      setSelectedZoneTypeList(pendingZoneTypeList);
      setSelectedZoneType(pendingZoneType);
      setSelectedSubZoneTypeList(pendingSubZoneTypeList);
      setSelectedSubZoneType(pendingSubZoneType);
      setSelectedFloorList(pendingFloorList);
      setSelectedFloor(pendingFloor);
      setSelectedRoomList(pendingRoomList);
      setSelectedRoom(pendingRoom);
    } catch (error) {
      setModalErrorMessage("빌리지 변경에 실패 하였습니다.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const changeZoneType = (zoneType) => {
    try {
      if (
        zoneType.status !== "available" ||
        selectedZoneType.zoneTypeName === zoneType.zoneTypeName
      )
        return;
      setIsLoading(true);
      let pendingAvailableRoom = _.find(totalRoomList, {
        propertyName: selectedProperty.propertyName,
        zoneTypeName: zoneType.zoneTypeName,
      });
      let pendingSubZoneTypeList = ["A동", "B동"].includes(
        pendingAvailableRoom.zoneTypeName
      )
        ? facility.subZoneType[pendingAvailableRoom.zoneTypeName]
        : [];
      let pendingSubZoneType = ["A동", "B동"].includes(
        pendingAvailableRoom.zoneTypeName
      )
        ? { subZoneTypeCode: subZoneTypeFormatter(pendingAvailableRoom.roomNo) }
        : {};
      let pendingFloorList =
        facility.floor[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ];
      let pendingFloor = { floorName: pendingAvailableRoom.floorName };
      let pendingRoomList =
        facility.room[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ][pendingAvailableRoom.floorName];
      let pendingRoom = { roomNo: pendingAvailableRoom.roomNo };

      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === zoneType.zoneTypeName
          ? pendingSubZoneTypeList.map((subZoneType) => {
              if (
                subZoneType.subZoneTypeCode ===
                subZoneTypeFormatter(availableRoom.roomNo)
              ) {
                availableRoom.subZoneTypeCode = subZoneType.subZoneTypeCode;
                subZoneType.status = "available";
              }
              if (
                subZoneType.subZoneTypeCode ===
                pendingSubZoneType.subZoneTypeCode
              )
                pendingSubZoneType = subZoneType;
              return subZoneType;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === zoneType.zoneTypeName
          ? pendingFloorList.map((floor) => {
              if (
                !pendingSubZoneType.subZoneTypeCode &&
                !floor.subZoneTypeCode &&
                floor.floorName === availableRoom.floorName
              )
                floor.status = "available";
              if (
                pendingSubZoneType.subZoneTypeCode ===
                  availableRoom.subZoneTypeCode &&
                floor.floorName === availableRoom.floorName
              )
                floor.status = "available";
              if (floor.floorName === pendingFloor.floorName)
                pendingFloor = floor;
              return floor;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === zoneType.zoneTypeName &&
        availableRoom.floorName === pendingFloor.floorName
          ? pendingRoomList.map((room) => {
              if (room.roomNo === availableRoom.roomNo) {
                room.status = "available";
                room.propertyName = availableRoom.propertyName;
                room.zoneTypeName = availableRoom.zoneTypeName;
                room.floorName = availableRoom.floorName;
              }
              if (room.roomNo === pendingRoom.roomNo) pendingRoom = room;
              return room;
            })
          : ""
      );
      setSelectedZoneType(zoneType);
      setSelectedSubZoneTypeList(pendingSubZoneTypeList);
      setSelectedSubZoneType(pendingSubZoneType);
      setSelectedFloorList(pendingFloorList);
      setSelectedFloor(pendingFloor);
      setSelectedRoomList(pendingRoomList);
      setSelectedRoom(pendingRoom);
    } catch (error) {
      setModalErrorMessage("콘도 변경에 실패 하였습니다.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const changeSubZoneType = (subZoneType) => {
    try {
      if (
        subZoneType.status !== "available" ||
        selectedSubZoneType.subZoneTypeCode === subZoneType.subZoneTypeCode
      )
        return;
      setIsLoading(true);
      let pendingAvailableRoom = _.find(totalRoomList, {
        propertyName: selectedProperty.propertyName,
        zoneTypeName: selectedZoneType.zoneTypeName,
        subZoneTypeCode: subZoneType.subZoneTypeCode,
      });
      const newFloor = {
        밸리빌리지: {
          A동: [
            { floorName: "1F", floorCode: "01", subZoneTypeCode: ["west"] },
            {
              floorName: "2F",
              floorCode: "02",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "3F",
              floorCode: "03",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "4F",
              floorCode: "04",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "5F",
              floorCode: "05",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "6F",
              floorCode: "06",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "7F",
              floorCode: "07",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "8F",
              floorCode: "08",
              subZoneTypeCode: ["east", "west"],
            },
            { floorName: "9F", floorCode: "09", subZoneTypeCode: ["east"] },
          ],
          B동: [
            { floorName: "1F", floorCode: "01", subZoneTypeCode: ["west"] },
            {
              floorName: "2F",
              floorCode: "02",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "3F",
              floorCode: "03",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "4F",
              floorCode: "04",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "5F",
              floorCode: "05",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "6F",
              floorCode: "06",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "7F",
              floorCode: "07",
              subZoneTypeCode: ["east", "west"],
            },
            {
              floorName: "8F",
              floorCode: "08",
              subZoneTypeCode: ["east", "west"],
            },
            { floorName: "9F", floorCode: "09", subZoneTypeCode: ["east"] },
          ],
        },
      };
      let pendingFloorList =
        newFloor[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ];
      let pendingFloor = { floorName: pendingAvailableRoom.floorName };
      let pendingRoomList =
        facility.room[pendingAvailableRoom.propertyName][
          pendingAvailableRoom.zoneTypeName
        ][pendingAvailableRoom.floorName];
      let pendingRoom = { roomNo: pendingAvailableRoom.roomNo };

      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === selectedZoneType.zoneTypeName &&
        availableRoom.subZoneTypeCode === subZoneType.subZoneTypeCode
          ? pendingFloorList.map((floor) => {
              if (floor.floorName === availableRoom.floorName)
                floor.status = "available";
              // if (!subZoneType.subZoneTypeCode && !floor.subZoneTypeCode && floor.floorName === availableRoom.floorName) floor.status = 'available';
              // if (subZoneType.subZoneTypeCode === availableRoom.subZoneTypeCode && floor.floorName === availableRoom.floorName) floor.status = 'available';
              if (floor.floorName === pendingFloor.floorName)
                pendingFloor = floor;
              return floor;
            })
          : ""
      );
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === selectedZoneType.zoneTypeName &&
        availableRoom.floorName === pendingFloor.floorName
          ? pendingRoomList.map((room) => {
              if (room.roomNo === availableRoom.roomNo) {
                room.status = "available";
                room.propertyName = availableRoom.propertyName;
                room.zoneTypeName = availableRoom.zoneTypeName;
                room.floorName = availableRoom.floorName;
              }
              if (room.roomNo === pendingRoom.roomNo) pendingRoom = room;
              return room;
            })
          : ""
      );
      setSelectedSubZoneType(subZoneType);
      setSelectedFloorList(pendingFloorList);
      setSelectedFloor(pendingFloor);
      setSelectedRoomList(pendingRoomList);
      setSelectedRoom(pendingRoom);
    } catch (error) {
      setModalErrorMessage("동관/서관 변경에 실패 하였습니다.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const changeFloor = (floor) => {
    try {
      if (
        floor.status !== "available" ||
        selectedFloor.floorName === floor.floorName
      )
        return;
      setIsLoading(true);
      let pendingAvailableRoom = {};
      if (selectedSubZoneType.subZoneTypeCode)
        pendingAvailableRoom = _.find(totalRoomList, {
          propertyName: selectedProperty.propertyName,
          zoneTypeName: selectedZoneType.zoneTypeName,
          subZoneTypeCode: selectedSubZoneType.subZoneTypeCode,
          floorName: floor.floorName,
        });
      else
        pendingAvailableRoom = _.find(totalRoomList, {
          propertyName: selectedProperty.propertyName,
          zoneTypeName: selectedZoneType.zoneTypeName,
          floorName: floor.floorName,
        });
      let pendingRoomList = [];
      let pendingRoom = {};
      pendingRoomList =
        facility.room[selectedProperty.propertyName][
          selectedZoneType.zoneTypeName
        ][floor.floorName];
      pendingRoom = { roomNo: pendingAvailableRoom.roomNo };
      totalRoomList.map((availableRoom) =>
        availableRoom.propertyName === selectedProperty.propertyName &&
        availableRoom.zoneTypeName === selectedZoneType.zoneTypeName &&
        availableRoom.floorName === floor.floorName
          ? pendingRoomList.map((room) => {
              if (room.roomNo === availableRoom.roomNo) {
                room.status = "available";
                room.propertyName = availableRoom.propertyName;
                room.zoneTypeName = availableRoom.zoneTypeName;
                room.floorName = availableRoom.floorName;
              }
              if (room.roomNo === pendingRoom.roomNo) pendingRoom = room;
              return room;
            })
          : ""
      );
      setSelectedFloor(floor);
      setSelectedRoomList(pendingRoomList);
      setSelectedRoom(pendingRoom);
    } catch (error) {
      setModalErrorMessage("층 변경에 실패 하였습니다.");
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const changeRoom = (room) => {
    if (room.status !== "available" || selectedRoom.roomNo === room.roomNo)
      return;
    setSelectedRoom(room);
  };

  useEffect(() => {
    if (bookingItem && bookingItem.rsvnStatusCode === "CI") {
      history.replace(
        `/?rsvnNo=${bookingItem.rsvnNo}&rsvnSeqNo=${bookingItem.rsvnSeqNo}`
      );
      return;
    } else if (!_.isEmpty(urlQueryStringParams)) {
      dispatch(
        bookingListAction.setBookingQueryStringParams(urlQueryStringParams)
      ); //결제 내역 스토리지에 등록.
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_room_list_by_payment",
        label: `${urlQueryStringParams.ResultCode}_${urlQueryStringParams.ResultMsg}}`,
      });
      registCheckInPayment();
      return;
    } else if (
      queryStringParams &&
      queryStringParams.ResultCode === "3001" &&
      queryStringParams.Moid === bookingItem.rsvnNo
    ) {
      registCheckInPayment(); //pg 결제는 저장한 상태지만 payment는 없을 경우 결제 이력 저장화면 전환
      return;
    } else if (bookingItem.roomNo) {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_room_list_by_exist_room",
        label: `${bookingItem.rsvnStatusCode}_${bookingItem.roomNo}`,
      });
      checkIn();
      return;
    } else {
      GoogleAnalytics.customEvent({
        category: "page_view",
        action: "page_view_room_list",
      });
      listAvailableRoom();
      return;
    }
    // confirmCheckInPayment();
    // listAvailableRoom();
    // testListAvailableRoom();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        customStyle={{ position: "relative", margin: 0 }}
        ContentBody={
          <RoomSelect
            selectedPropertyList={selectedPropertyList}
            selectedProperty={selectedProperty}
            selectedZoneTypeList={selectedZoneTypeList}
            selectedZoneType={selectedZoneType}
            selectedSubZoneTypeList={selectedSubZoneTypeList}
            selectedSubZoneType={selectedSubZoneType}
            selectedFloorList={selectedFloorList}
            selectedFloor={selectedFloor}
            selectedRoomList={selectedRoomList}
            selectedRoom={selectedRoom}
            changeProperty={changeProperty}
            changeZoneType={changeZoneType}
            changeSubZoneType={changeSubZoneType}
            changeFloor={changeFloor}
            changeRoom={changeRoom}
            checkIn={checkIn}
            history={history}
          />
        }
      />
      <PaymentError
        isOpen={isOpenPaymentError}
        message={paymentErrorMessage}
        onClose={closePaymentError}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner isLoading={isLoading} />
    </Fragment>
  );
};

export default RoomSelectContainer;
