import React, { Fragment, useEffect } from "react";
import * as GoogleAnalytics from "../../lib/google-analytics";
import Modal from "../Modal";

const ModalLocationSerivceGuideIOS = ({ isOpen, onClose, history }) => {
  const confirm = async () => {
    GoogleAnalytics.customEvent({
      category: "button_click",
      action: "button_click_ios_guide_confirm",
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen)
      GoogleAnalytics.customEvent({
        category: "modal_view",
        action: "modal_view_location_service_ios_guide",
      });
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        size="medium"
        Content={
          <Fragment>
            <div className="w-100 mb-15">
              <button className="confirm-button h-40 mb-14" onClick={confirm}>
                확인
              </button>
              <img
                className="w-100"
                src="../../assets/images/ios-location-service-guide.png"
                alt=""
              />
            </div>
            <button className="confirm-button h-40 mb-14" onClick={confirm}>
              확인
            </button>
          </Fragment>
        }
      />
    </Fragment>
  );
};

export default ModalLocationSerivceGuideIOS;
