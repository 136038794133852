import React, { Fragment, useState, useEffect } from 'react';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useSelector } from 'react-redux';
// import moment from 'moment';
import MainLayout from '../components/layouts/MainLayout';
import Payment from '../components/views/Payment';
import ModalError from '../components/ModalError';
import * as imgateApi from '../api/imgate';

const CheckOutPaymentContainer = ({
  history,
  match,
}) => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  const now = history.location.state ? history.location.state.now : '';
  const totalAmount = history.location.state ? history.location.state.totalAmount : '';
  const { bookingItem, userInfo } = useSelector(state => state.bookingList);
  const [ encryptedData, setEncryptedData ] = useState('');
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    // history.replace('/');
  };

  const encryptData = async () => {
    try {
      const { data: encryptDataResponse } = await imgateApi.encryptData({
        EdiDate: now,
        Amt: isTestMode ? 1000 : totalAmount,
      });
      GoogleAnalytics.customEvent({
        category: 'api_response',
        action: 'api_response_ok_encryptData',
      });
      setEncryptedData(encryptDataResponse.EncryptData);
    } catch (error) {
      setModalErrorMessage('결제 데이터 생성에 실패 하였습니다.');
      setModalErrorSubMessage(error.response ? `${error.response.data.code}, ${error.response.data.message}` : error.message);
      openModalError();
    }
  };

  useEffect(() => {
    encryptData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkout_payment',
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${totalAmount}`,
    });
  }, []);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <Payment
            bookingItem={bookingItem}
            userInfo={userInfo}
            productName='추가 사용료'
            totalAmount={totalAmount}
            now={now}
            encryptedData={encryptedData}
            returnUrl={process.env.REACT_APP_SMARTRO_CHECKOUT_RETURN_URL}
            stopUrl={process.env.REACT_APP_SMARTRO_CHECKOUT_STOP_URL}
            history={history}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
    </Fragment>
  );
};

export default CheckOutPaymentContainer;