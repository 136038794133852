import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_ENV === 'production' ? "https://7d313e6f0f564310800472a006aff6e3@o4505192173273088.ingest.sentry.io/4505326932852736" : undefined,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
});

ReactDOM.render(<App />, document.getElementById('root'));
