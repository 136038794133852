import React, { Fragment, useState } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import Checkbox from '../Checkbox';
import PrivacyPolicy from './PrivacyPolicy';

const TermsOfUseList = ({
  history,
}) => {
  const [ isCheckedAll, setIsCheckedAll ] = useState(false);
  const [ isCheckedPrivacy, setIsCheckedPrivacy ] = useState(false);
  const [ isCheckedThirdParty, setIsCheckedThirdParty ] = useState(false);
  const [ isCheckedLocation, setIsCheckedLocation ] = useState(false);
  const [ isCheckedAge, setIsCheckedAge ] = useState(false);
  const [ isCheckedMarketing, setIsCheckedMarketing ] = useState(false);
  const [ isOpenModalPDFAgreement, setIsOpenModalPDFAgreement ] = useState(false);
  const isCheckedRequired = isCheckedPrivacy && isCheckedThirdParty && isCheckedLocation && isCheckedAge;

  const openModalPDFAgreement = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_privacy_policy',
    });
    setIsOpenModalPDFAgreement(true);
  };

  const closeModalPDFAgreement = () => {
    setIsOpenModalPDFAgreement(false);
  };

  const checkAll = () => {
    setIsCheckedAll(!isCheckedAll);
    setIsCheckedPrivacy(!isCheckedAll);
    setIsCheckedThirdParty(!isCheckedAll);
    setIsCheckedLocation(!isCheckedAll);
    setIsCheckedAge(!isCheckedAll);
    setIsCheckedMarketing(!isCheckedAll);
  };

  const onOk = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_terms_of_use_ok',
    });
    history.push('/checkin');
  };

  return (
    <Fragment>
      <span className='terms-of-use-list-title'>
        약관 동의
      </span>
      <span className='terms-of-use-list-description'>
        아래 약관 내용에 동의 후 서비스 이용이 가능합니다.
      </span>
      <div className='all-agree-checkbox-container'>
        <Checkbox isChecked={isCheckedAll} onCheck={checkAll} />
        <span className='all-agree-checkbox-text'>
          전체 동의
        </span>
        <span className='all-agree-checkbox-description'>
          (선택항목 포함)
        </span>
      </div>
      <div className='horizontal-line mt-4 mb-12'></div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedPrivacy} onCheck={() => setIsCheckedPrivacy(!isCheckedPrivacy)} />
        <span className='agree-checkbox-text'>
          개인정보의 수집·이용에 관한 사항 (필수)
        </span>
        <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' onClick={openModalPDFAgreement} />
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedThirdParty} onCheck={() => setIsCheckedThirdParty(!isCheckedThirdParty)} />
        <span className='agree-checkbox-text'>
          개인정보의 제3자 제공에 관한 사항 - 회사의 국내 및 해외 체인 호텔 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedLocation} onCheck={() => setIsCheckedLocation(!isCheckedLocation)} />
        <span className='agree-checkbox-text'>
          위치기반서비스 이용약관 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedAge} onCheck={() => setIsCheckedAge(!isCheckedAge)} />
        <span className='agree-checkbox-text'>
          만 14세 이상 이용 동의 (필수)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' /> */}
      </div>
      <div className='agree-checkbox-container'>
        <Checkbox isChecked={isCheckedMarketing} onCheck={() => setIsCheckedMarketing(!isCheckedMarketing)} />
        <span className='agree-checkbox-text'>
          이벤트 및 마케팅 활용 동의 (선택)
        </span>
        {/* <img className='detail-view' src='../../assets/images/arrow-right.png' alt='' onClick={openModalPDFAgreement} /> */}
      </div>
      <button className='confirm-button mt-22' disabled={!isCheckedRequired} onClick={onOk}>
        확인
      </button>
      <PrivacyPolicy isOpen={isOpenModalPDFAgreement} onClose={closeModalPDFAgreement}/>
    </Fragment>
  );
};

export default TermsOfUseList;