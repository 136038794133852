import React, { Fragment, useState, useEffect, useCallback } from 'react';
import * as GoogleAnalytics from '../lib/google-analytics';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import MainLayout from '../components/layouts/MainLayout';
import Payment from '../components/views/Payment';
import ModalError from '../components/ModalError';
import Spinner from '../components/Spinner';
import * as api from '../api/sanha';
import * as imgateApi from '../api/imgate';
import * as Sentry from '@sentry/react';
import { bookingListAction } from '../stores/actions';

const CheckInPaymentContainer = ({
  history,
  match,
}) => {
  const isTestMode = process.env.REACT_APP_ENV === 'production' ? false : true;
  // const now = moment().format('YYYYMMDDHHmmss');
  const { bookingItem, userInfo, paymentInfo, queryStringParams } = useSelector(state => state.bookingList);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ now, setNow ] = useState('');
  const [ totalAmount, setTotalAmount ] = useState('');
  const [ encryptedData, setEncryptedData ] = useState('');
  const [ isOpenModalError, setIsOpenModalError ] = useState(false);
  const [ modalErrorMessage, setModalErrorMessage ] = useState('');
  const [ modalErrorSubMessage, setModalErrorSubMessage ] = useState('');
  const dispatch = useDispatch();

  const openModalError = () => {
    setIsOpenModalError(true);
  };

  const closeModalError = () => {
    setIsOpenModalError(false);
    // history.replace('/');
  };

  const confirmCheckInPayment = async () => {
    try {
      setIsLoading(true);
      const { data: confirmCheckInPaymentResponse } = await api.confirmCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
      });
      if (confirmCheckInPaymentResponse.userStatus.code !== 2000) {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_fail_confirm_checkin_payment',
          label: `${confirmCheckInPaymentResponse.userStatus.code}_${confirmCheckInPaymentResponse.userStatus.message}`,
        });
        setModalErrorMessage('예약 결제 여부 확인에 실패 하였습니다.');
        throw new Error(`${confirmCheckInPaymentResponse.userStatus.code}, ${confirmCheckInPaymentResponse.userStatus.message}`);
      } else if (confirmCheckInPaymentResponse.resultData.payYn === 'Y') {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_ok_confirm_checkin_payment',
          label: `${confirmCheckInPaymentResponse.userStatus.code}_${confirmCheckInPaymentResponse.resultData.payYn}`,
        });
        history.replace('/room');
      } else {
        const { data: confirmCheckInAmountResponse } = await api.confirmCheckInAmount({
          rsvnNo: bookingItem.rsvnNo,
          rsvnSeqNo: bookingItem.rsvnSeqNo,
        });
        if (confirmCheckInAmountResponse.userStatus.code !== 2000) {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_fail_confirm_checkin_amount',
            label: `${confirmCheckInAmountResponse.userStatus.code}_${confirmCheckInAmountResponse.userStatus.message}`,
          });
          setModalErrorMessage('예약 결제 금액 확인에 실패 하였습니다.');
          throw new Error(`${confirmCheckInAmountResponse.userStatus.code}, ${confirmCheckInAmountResponse.userStatus.message}`);
        } else if (!confirmCheckInAmountResponse.resultData.totalAmount) {
          GoogleAnalytics.customEvent({
            category: 'api_response',
            action: 'api_response_ok_confirm_checkin_amount',
            label: `${confirmCheckInAmountResponse.userStatus.code}_${confirmCheckInAmountResponse.resultData.totalAmount}`,
          });
          history.replace('/room');
        } else {
          const currentTime = moment().format('YYYYMMDDHHmmss');
          setNow(currentTime);
          setTotalAmount(confirmCheckInAmountResponse.resultData.totalAmount);
          await encryptData({
            EdiDate: currentTime,
            Amt: isTestMode ? 1000 : confirmCheckInAmountResponse.resultData.totalAmount,
          });
        }
      }
    } catch (error) {
      setModalErrorSubMessage(error.message);
      openModalError();
    } finally {
      setIsLoading(false);
    }
  };

  const encryptData = async ({EdiDate, Amt}) => {
    try {
      const { data: encryptDataResponse } = await imgateApi.encryptData({
        EdiDate,
        Amt,
      });
      GoogleAnalytics.customEvent({
        category: 'api_response',
        action: 'api_response_ok_encryptData',
      });
      setEncryptedData(encryptDataResponse.EncryptData);
    } catch (error) {
      setModalErrorMessage('결제 데이터 생성에 실패 하였습니다.');
      throw new Error(error.response ? `${error.response.data.code}, ${error.response.data.message}` : error.message);
    }
  };

  const registCheckInPayment = useCallback(async () => {
    try {
      const { data: registCheckInPaymentResponse } = await api.registCheckInPayment({
        rsvnNo: bookingItem.rsvnNo,
        rsvnSeqNo: bookingItem.rsvnSeqNo,
        trnsNo: queryStringParams.Tid,
        intApprTypeCode: 'P',
        intPayStatusCode: 11,
        payFnshDate: '',
        successYn: queryStringParams.ResultCode === '3001' ? 'Y' : 'N',
        trnsDate: moment().format('YYYYMMDD'),
        trnsHHMMSS: moment().format('HHmmss'),
        trnsAmt: queryStringParams.Amt,
        cardMonthCnt: queryStringParams.CardQuota || '',
        cardApprNo: queryStringParams.AuthCode || '',
        cardName: queryStringParams.AppCardName || '',
        cardIssueCode: queryStringParams.AppCardCode || '',
        cardPurcCode: queryStringParams.AcquCardCode || '',
        trnsBankCode: '',
        acctNo: '',
        depositor: queryStringParams.BuyerName || '',
        cashRcptTypeCode: 0,
        cashRcptApptNo: '',
        cashRcptCxlApptNo: '',
        responseCode: queryStringParams.ResultCode || '',
        message1: queryStringParams.ResultMsg || '',
        message2: '',
        dpsitPayDivCode: '01',
        userId: 'KEYLESS',
        userIp: '127.0.0.0',
        payMgtNo: '',
        dpsitPayNo: '',
        outYN: '',
        outMsg: '',
      });
      if (registCheckInPaymentResponse.userStatus.code !== 2000) {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_fail_regist_checkin_payment',
          label: `${registCheckInPaymentResponse.userStatus.code}_${registCheckInPaymentResponse.userStatus.message}`,
        });
        Sentry.captureMessage(`registCheckInPayment_response_Error : ${registCheckInPaymentResponse.userStatus.code}: ${registCheckInPaymentResponse.userStatus.message} (예약번호:${bookingItem.rsvnNo})`);
        setModalErrorMessage('결제는 성공하였으나, 이 후 처리 과정에서 문제가 생겼습니다.');
        throw new Error(`${registCheckInPaymentResponse.userStatus.code}, ${registCheckInPaymentResponse.userStatus.message}`);
      } else {
        GoogleAnalytics.customEvent({
          category: 'api_response',
          action: 'api_response_ok_regist_checkin_payment',
        });
        dispatch(bookingListAction.setPaymentInfo({
          rsvnNo: bookingItem.rsvnNo,
          isPaid: true,
        }));
        dispatch(bookingListAction.setBookingQueryStringParams('')); //결제 내역이 등록되었으면, 초기화
        history.replace('/room');
      }
    } catch (error) {
      Sentry.captureMessage(`registCheckInPayment_response_Error : ${error.response?.data?.message}: ${error.message} (예약번호:${bookingItem.rsvnNo})`);
      setModalErrorSubMessage(error.message);
      openModalError();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingItem && bookingItem.rsvnStatusCode === 'CI') history.replace(`/?rsvnNo=${bookingItem.rsvnNo}&rsvnSeqNo=${bookingItem.rsvnSeqNo}`);
    else if (paymentInfo && paymentInfo.isPaid && paymentInfo.rsvnNo === bookingItem.rsvnNo) history.replace('/room');
    //Moid가 response 되는지 확인 필요!!!!
    else if(queryStringParams && queryStringParams.ResultCode === '3001'  && queryStringParams.Moid === bookingItem.rsvnNo) registCheckInPayment(); //pg 결제는 저장한 상태지만 payment는 없을 경우 결제 이력 저장화면 전환
    else confirmCheckInPayment();    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_checkin_payment',
      label: `${bookingItem.rsvnNo}_${bookingItem.rsvnStatusCode}_${bookingItem.guestName}`,
    });
  }, [bookingItem.guestName, bookingItem.rsvnNo, bookingItem.rsvnStatusCode]);

  return (
    <Fragment>
      <MainLayout
        history={history}
        ContentBody={(
          <Payment
            bookingItem={bookingItem}
            userInfo={userInfo}
            productName='객실 숙박료'
            totalAmount={totalAmount}
            now={now}
            encryptedData={encryptedData}
            returnUrl={process.env.REACT_APP_SMARTRO_CHECKIN_RETURN_URL}
            stopUrl={process.env.REACT_APP_SMARTRO_CHECKIN_STOP_URL}
            history={history}
          />
        )}
      />
      <ModalError
        isOpen={isOpenModalError}
        message={modalErrorMessage}
        subMessage={modalErrorSubMessage}
        onClose={closeModalError}
      />
      <Spinner
        isLoading={isLoading}
      />
    </Fragment>
  );
};

export default CheckInPaymentContainer;