export const property = [
  { propertyName: "밸리빌리지", propertyNo: "11" },
  { propertyName: "힐스빌리지", propertyNo: "21" },
];

export const condo = {
  밸리빌리지: [
    { zoneTypeName: "A동", zoneTypeCode: "A" },
    { zoneTypeName: "B동", zoneTypeCode: "B" },
    { zoneTypeName: "사우스동", zoneTypeCode: "S" },
    { zoneTypeName: "노스동", zoneTypeCode: "N" },
  ],
  힐스빌리지: [
    { zoneTypeName: "C동", zoneTypeCode: "C" },
    { zoneTypeName: "D동", zoneTypeCode: "D" },
  ],
};

export const subZoneType = {
  A동: [
    { subZoneTypeName: "동관", subZoneTypeCode: "east" },
    { subZoneTypeName: "서관", subZoneTypeCode: "west" },
  ],
  B동: [
    { subZoneTypeName: "동관", subZoneTypeCode: "east" },
    { subZoneTypeName: "서관", subZoneTypeCode: "west" },
  ],
};

export const floor = {
  밸리빌리지: {
    A동: [
      { floorName: "1F", floorCode: "01", subZoneTypeCode: ["west"] },
      { floorName: "2F", floorCode: "02", subZoneTypeCode: ["east", "west"] },
      { floorName: "3F", floorCode: "03", subZoneTypeCode: ["east", "west"] },
      { floorName: "4F", floorCode: "04", subZoneTypeCode: ["east", "west"] },
      { floorName: "5F", floorCode: "05", subZoneTypeCode: ["east", "west"] },
      { floorName: "6F", floorCode: "06", subZoneTypeCode: ["east", "west"] },
      { floorName: "7F", floorCode: "07", subZoneTypeCode: ["east", "west"] },
      { floorName: "8F", floorCode: "08", subZoneTypeCode: ["east", "west"] },
      { floorName: "9F", floorCode: "09", subZoneTypeCode: ["east"] },
    ],
    B동: [
      { floorName: "1F", floorCode: "01", subZoneTypeCode: ["west"] },
      { floorName: "2F", floorCode: "02", subZoneTypeCode: ["east", "west"] },
      { floorName: "3F", floorCode: "03", subZoneTypeCode: ["east", "west"] },
      { floorName: "4F", floorCode: "04", subZoneTypeCode: ["east", "west"] },
      { floorName: "5F", floorCode: "05", subZoneTypeCode: ["east", "west"] },
      { floorName: "6F", floorCode: "06", subZoneTypeCode: ["east", "west"] },
      { floorName: "7F", floorCode: "07", subZoneTypeCode: ["east", "west"] },
      { floorName: "8F", floorCode: "08", subZoneTypeCode: ["east", "west"] },
      { floorName: "9F", floorCode: "09", subZoneTypeCode: ["east"] },
    ],
    사우스동: [
      { floorName: "1F", floorCode: "01" },
      { floorName: "2F", floorCode: "02" },
      { floorName: "3F", floorCode: "03" },
      { floorName: "4F", floorCode: "04" },
      { floorName: "5F", floorCode: "05" },
      { floorName: "6F", floorCode: "06" },
      { floorName: "7F", floorCode: "07" },
    ],
    노스동: [
      { floorName: "1F", floorCode: "01" },
      { floorName: "2F", floorCode: "02" },
      { floorName: "3F", floorCode: "03" },
      { floorName: "4F", floorCode: "04" },
      { floorName: "5F", floorCode: "05" },
      { floorName: "6F", floorCode: "06" },
      { floorName: "7F", floorCode: "07" },
      { floorName: "8F", floorCode: "08" },
    ],
  },
  힐스빌리지: {
    C동: [
      { floorName: "3F", floorCode: "03" },
      { floorName: "4F", floorCode: "04" },
      { floorName: "5F", floorCode: "05" },
      { floorName: "6F", floorCode: "06" },
      { floorName: "7F", floorCode: "07" },
      { floorName: "8F", floorCode: "08" },
      { floorName: "9F", floorCode: "09" },
      { floorName: "10F", floorCode: "10" },
      { floorName: "11F", floorCode: "11" },
      { floorName: "12F", floorCode: "12" },
      { floorName: "13F", floorCode: "13" },
      { floorName: "14F", floorCode: "14" },
      { floorName: "15F", floorCode: "15" },
    ],
    D동: [
      { floorName: "3F", floorCode: "03" },
      { floorName: "4F", floorCode: "04" },
      { floorName: "5F", floorCode: "05" },
      { floorName: "6F", floorCode: "06" },
      { floorName: "7F", floorCode: "07" },
      { floorName: "8F", floorCode: "08" },
      { floorName: "9F", floorCode: "09" },
      { floorName: "10F", floorCode: "10" },
      { floorName: "11F", floorCode: "11" },
      { floorName: "12F", floorCode: "12" },
    ],
  },
};

export const room = {
  밸리빌리지: {
    A동: {
      "1F": [
        {
          roomNo: "410116",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 8,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410115",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410114",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410113",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410112",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410111",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410110",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410109",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410108",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410107",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410106",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410105",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410104",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410103",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410102",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410101",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
      ],
      "2F": [
        {
          roomNo: "410216",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 8,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410215",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410214",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410213",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410212",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410211",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410210",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410209",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410208",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410207",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410206",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410205",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410204",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410203",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410202",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410201",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "elevator",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410265",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 8,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410266",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410263",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410264",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410261",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410262",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410259",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410260",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410257",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410258",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410255",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410256",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410253",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410254",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410251",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410252",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "3F": [
        {
          roomNo: "410316",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 8,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410315",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410314",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410313",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410312",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410311",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410310",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410309",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410308",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410307",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410306",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410305",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410304",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410303",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410302",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410301",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410365",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 8,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410366",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410363",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410364",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410361",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410362",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410359",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410360",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410357",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410358",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410355",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410356",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410353",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410354",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410351",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410352",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "4F": [
        {
          roomNo: "410416",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 8,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410415",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410414",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410413",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410412",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410411",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410410",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410409",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410408",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410407",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410406",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410405",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410404",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410403",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410402",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410401",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410465",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 8,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410466",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410463",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410464",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410461",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410462",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410459",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410460",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410457",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410458",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410455",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410456",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410453",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410454",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410451",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410452",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "5F": [
        {
          roomNo: "410514",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 7,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410513",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410512",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410511",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410510",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410509",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410508",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410507",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410506",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410505",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410504",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410503",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410502",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410501",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410565",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 8,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410566",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410563",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410564",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410561",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410562",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410559",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410560",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410557",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410558",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410555",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410556",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410553",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410554",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410551",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410552",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "6F": [
        {
          roomNo: "410614",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 7,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410613",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410612",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410611",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410610",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410609",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410608",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410607",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410606",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410605",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410604",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410603",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410602",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410601",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410663",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 7,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410664",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410661",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410662",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410659",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410660",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410657",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410658",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410655",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410656",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410653",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410654",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410651",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410652",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "7F": [
        {
          roomNo: "410710",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 5,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410709",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410708",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410707",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410706",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410705",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410704",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410703",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410702",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410701",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410763",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 7,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410764",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410761",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410762",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410759",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410760",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410757",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410758",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410755",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410756",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410753",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410754",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410751",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410752",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "8F": [
        {
          roomNo: "410810",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 5,
          view: "east",
          position: "left",
        },
        {
          roomNo: "410809",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410808",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410807",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410806",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410805",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410804",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410803",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "410802",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "410801",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "410857",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 4,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410858",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410855",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410856",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410853",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410854",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410851",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410852",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "9F": [
        {
          roomNo: "410957",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 4,
          view: "west",
          position: "left",
        },
        {
          roomNo: "410958",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410955",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410956",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410953",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410954",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "410951",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "410952",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
    },
    B동: {
      "1F": [
        {
          roomNo: "420120",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 10,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420119",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420118",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420117",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420116",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420114",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420113",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420112",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420111",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420110",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420109",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420108",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420106",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420105",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420104",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420103",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420102",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420101",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
      ],
      "2F": [
        {
          roomNo: "420220",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 10,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420219",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420218",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420217",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420216",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420214",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420213",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420212",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420211",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420210",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420209",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420208",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420206",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420205",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420204",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420203",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420202",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420201",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420269",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 10,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420270",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420267",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420268",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420265",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420266",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420263",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420264",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420261",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420262",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420259",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420260",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420258",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420255",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420256",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420253",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420254",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420251",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420252",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "3F": [
        {
          roomNo: "420320",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 10,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420319",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420318",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420317",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420316",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420314",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420313",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420312",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420311",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420310",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420309",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420308",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420306",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420305",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420304",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420303",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420302",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420301",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420369",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 10,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420370",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420367",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420368",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420365",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420366",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420363",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420364",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420361",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420362",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420359",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420360",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420358",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420355",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420356",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420353",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420354",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420351",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420352",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "4F": [
        {
          roomNo: "420420",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 10,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420419",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420418",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420417",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420416",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420414",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420413",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420412",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420411",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420410",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420409",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420408",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420406",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420405",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420404",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420403",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420402",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420401",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420469",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 10,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420470",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420467",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420468",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420465",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420466",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420463",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420464",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420461",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420462",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420459",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420460",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420458",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420455",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420456",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420453",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420454",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420451",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420452",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "5F": [
        {
          roomNo: "420514",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 7,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420513",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420512",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420511",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420510",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420509",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420508",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420506",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420505",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420504",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420503",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420502",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420501",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420569",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 10,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420570",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420567",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420568",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420565",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420566",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420563",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420564",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420561",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420562",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420559",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420560",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420558",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420555",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420556",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420553",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420554",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420551",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420552",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "6F": [
        {
          roomNo: "420614",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 7,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420613",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420612",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420611",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420610",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420609",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420608",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "west" },
        {
          roomNo: "420606",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420605",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420604",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420603",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420602",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420601",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420663",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 7,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420664",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420661",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420662",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420659",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420660",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420658",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420655",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420656",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420653",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420654",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420651",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420652",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "7F": [
        {
          roomNo: "420706",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 3,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420705",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420704",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420703",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420702",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420701",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420763",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 7,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420764",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420761",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420762",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420759",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420760",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "", isEmpty: true, subZoneTypeCode: "east" },
        {
          roomNo: "420758",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420755",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420756",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420753",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420754",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420751",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420752",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "8F": [
        {
          roomNo: "420806",
          subZoneTypeCode: "west",
          corridorStart: 1,
          corridorSize: 3,
          view: "east",
          position: "left",
        },
        {
          roomNo: "420805",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420804",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420803",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        {
          roomNo: "420802",
          subZoneTypeCode: "west",
          view: "east",
          position: "left",
        },
        {
          roomNo: "420801",
          subZoneTypeCode: "west",
          view: "west",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "west" },
        {
          roomNo: "420855",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 3,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420856",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420853",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420854",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420851",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420852",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
      "9F": [
        {
          roomNo: "420955",
          subZoneTypeCode: "east",
          corridorStart: 1,
          corridorSize: 3,
          view: "west",
          position: "left",
        },
        {
          roomNo: "420956",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420953",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420954",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        {
          roomNo: "420951",
          subZoneTypeCode: "east",
          view: "west-north",
          position: "left",
        },
        {
          roomNo: "420952",
          subZoneTypeCode: "east",
          view: "east-south",
          position: "right",
        },
        { roomNo: "elevator", subZoneTypeCode: "east" },
      ],
    },
    사우스동: {
      "1F": [
        { roomNo: "460101", isCenter: true },
        {
          roomNo: "460102",
          corridorStart: 2,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460103", view: "south", position: "right" },
        { roomNo: "460104", view: "north", position: "left" },
        { roomNo: "460105", view: "south", position: "right" },
        { roomNo: "460106", view: "north", position: "left" },
        { roomNo: "460107", view: "south", position: "right" },
        { roomNo: "460108", view: "north", position: "left" },
        { roomNo: "460109", view: "south", position: "right" },
        { roomNo: "460110", view: "north", position: "left" },
        { roomNo: "460111", view: "south", position: "right" },
        { roomNo: "460112", view: "north", position: "left" },
        { roomNo: "460113", view: "south", position: "right" },
        { roomNo: "elevator" },
        { roomNo: "", isEmpty: true, corridorStart: 9, corridorSize: 8 },
        { roomNo: "460115", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460117", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460119", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460121", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460123", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460125", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460127", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460129", view: "west-south", position: "right" },
      ],
      "2F": [
        { roomNo: "460201", isCenter: true },
        {
          roomNo: "460202",
          corridorStart: 2,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460203", view: "south", position: "right" },
        { roomNo: "460204", view: "north", position: "left" },
        { roomNo: "460205", view: "south", position: "right" },
        { roomNo: "460206", view: "north", position: "left" },
        { roomNo: "460207", view: "south", position: "right" },
        { roomNo: "460208", view: "north", position: "left" },
        { roomNo: "460209", view: "south", position: "right" },
        { roomNo: "460210", view: "north", position: "left" },
        { roomNo: "460211", view: "south", position: "right" },
        { roomNo: "460212", view: "north", position: "left" },
        { roomNo: "460213", view: "south", position: "right" },
        { roomNo: "elevator" },
        { roomNo: "", isEmpty: true, corridorStart: 9, corridorSize: 8 },
        { roomNo: "460215", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460217", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460219", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460221", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460223", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460225", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460227", view: "west-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "460229", view: "west-south", position: "right" },
      ],
      "3F": [
        { roomNo: "460301", isCenter: true },
        {
          roomNo: "460302",
          corridorStart: 2,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460303", view: "south", position: "right" },
        { roomNo: "460304", view: "north", position: "left" },
        { roomNo: "460305", view: "south", position: "right" },
        { roomNo: "460306", view: "north", position: "left" },
        { roomNo: "460307", view: "south", position: "right" },
        { roomNo: "460308", view: "north", position: "left" },
        { roomNo: "460309", view: "south", position: "right" },
        { roomNo: "460310", view: "north", position: "left" },
        { roomNo: "460311", view: "south", position: "right" },
        { roomNo: "460312", view: "north", position: "left" },
        { roomNo: "460313", view: "south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "460314",
          corridorStart: 9,
          corridorSize: 8,
          view: "north",
          position: "left",
        },
        { roomNo: "460315", view: "west-south", position: "right" },
        { roomNo: "460316", view: "east-north", position: "left" },
        { roomNo: "460317", view: "west-south", position: "right" },
        { roomNo: "460318", view: "east-north", position: "left" },
        { roomNo: "460319", view: "west-south", position: "right" },
        { roomNo: "460320", view: "east-north", position: "left" },
        { roomNo: "460321", view: "west-south", position: "right" },
        { roomNo: "460322", view: "east-north", position: "left" },
        { roomNo: "460323", view: "west-south", position: "right" },
        { roomNo: "460324", view: "east-north", position: "left" },
        { roomNo: "460325", view: "west-south", position: "right" },
        { roomNo: "460326", view: "east-north", position: "left" },
        { roomNo: "460327", view: "west-south", position: "right" },
        { roomNo: "460328", view: "east-north", position: "left" },
        { roomNo: "460329", view: "west-south", position: "right" },
      ],
      "4F": [
        { roomNo: "460401", isCenter: true },
        {
          roomNo: "460402",
          corridorStart: 2,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460403", view: "south", position: "right" },
        { roomNo: "460404", view: "north", position: "left" },
        { roomNo: "460405", view: "south", position: "right" },
        { roomNo: "460406", view: "north", position: "left" },
        { roomNo: "460407", view: "south", position: "right" },
        { roomNo: "460408", view: "north", position: "left" },
        { roomNo: "460409", view: "south", position: "right" },
        { roomNo: "460410", view: "north", position: "left" },
        { roomNo: "460411", view: "south", position: "right" },
        { roomNo: "460412", view: "north", position: "left" },
        { roomNo: "460413", view: "south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "460414",
          corridorStart: 9,
          corridorSize: 7,
          view: "north",
          position: "left",
        },
        { roomNo: "460415", view: "west-south", position: "right" },
        { roomNo: "460416", view: "east-north", position: "left" },
        { roomNo: "460417", view: "west-south", position: "right" },
        { roomNo: "460418", view: "east-north", position: "left" },
        { roomNo: "460419", view: "west-south", position: "right" },
        { roomNo: "460420", view: "east-north", position: "left" },
        { roomNo: "460421", view: "west-south", position: "right" },
        { roomNo: "460422", view: "east-north", position: "left" },
        { roomNo: "460423", view: "west-south", position: "right" },
        { roomNo: "460424", view: "east-north", position: "left" },
        { roomNo: "460425", view: "west-south", position: "right" },
        { roomNo: "460426", view: "east-north", position: "left" },
        { roomNo: "460427", view: "west-south", position: "right" },
      ],
      "5F": [
        { roomNo: "460501", isCenter: true },
        {
          roomNo: "460502",
          corridorStart: 2,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460503", view: "south", position: "right" },
        { roomNo: "460504", view: "north", position: "left" },
        { roomNo: "460505", view: "south", position: "right" },
        { roomNo: "460506", view: "north", position: "left" },
        { roomNo: "460507", view: "south", position: "right" },
        { roomNo: "460508", view: "north", position: "left" },
        { roomNo: "460509", view: "south", position: "right" },
        { roomNo: "460510", view: "north", position: "left" },
        { roomNo: "460511", view: "south", position: "right" },
        { roomNo: "460512", view: "north", position: "left" },
        { roomNo: "460513", view: "south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "460514",
          corridorStart: 9,
          corridorSize: 7,
          view: "north",
          position: "left",
        },
        { roomNo: "460515", view: "west-south", position: "right" },
        { roomNo: "460516", view: "east-north", position: "left" },
        { roomNo: "460517", view: "west-south", position: "right" },
        { roomNo: "460518", view: "east-north", position: "left" },
        { roomNo: "460519", view: "west-south", position: "right" },
        { roomNo: "460520", view: "east-north", position: "left" },
        { roomNo: "460521", view: "west-south", position: "right" },
        { roomNo: "460522", view: "east-north", position: "left" },
        { roomNo: "460523", view: "west-south", position: "right" },
        { roomNo: "460524", view: "east-north", position: "left" },
        { roomNo: "460525", view: "west-south", position: "right" },
        { roomNo: "460526", view: "east-north", position: "left" },
        { roomNo: "460527", view: "west-south", position: "right" },
      ],
      "6F": [
        {
          roomNo: "460602",
          corridorStart: 1,
          corridorSize: 6,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460603", view: "south", position: "right" },
        { roomNo: "460604", view: "north", position: "left" },
        { roomNo: "460605", view: "south", position: "right" },
        { roomNo: "460606", view: "north", position: "left" },
        { roomNo: "460607", view: "south", position: "right" },
        { roomNo: "460608", view: "north", position: "left" },
        { roomNo: "460609", view: "south", position: "right" },
        { roomNo: "460610", view: "north", position: "left" },
        { roomNo: "460611", view: "south", position: "right" },
        { roomNo: "460612", view: "north", position: "left" },
        { roomNo: "460613", view: "south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "460614",
          corridorStart: 8,
          corridorSize: 5,
          view: "north",
          position: "left",
        },
        { roomNo: "460615", view: "west-south", position: "right" },
        { roomNo: "460616", view: "east-north", position: "left" },
        { roomNo: "460617", view: "west-south", position: "right" },
        { roomNo: "460618", view: "east-north", position: "left" },
        { roomNo: "460619", view: "west-south", position: "right" },
        { roomNo: "460620", view: "east-north", position: "left" },
        { roomNo: "460621", view: "west-south", position: "right" },
        { roomNo: "460622", view: "east-north", position: "left" },
        { roomNo: "460623", view: "west-south", position: "right" },
      ],
      "7F": [
        {
          roomNo: "460706",
          corridorStart: 1,
          corridorSize: 4,
          view: "east-north",
          position: "left",
        },
        { roomNo: "460707", view: "south", position: "right" },
        { roomNo: "460708", view: "north", position: "left" },
        { roomNo: "460709", view: "south", position: "right" },
        { roomNo: "460710", view: "north", position: "left" },
        { roomNo: "460711", view: "south", position: "right" },
        { roomNo: "460712", view: "north", position: "left" },
        { roomNo: "460713", view: "south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "460714",
          corridorStart: 6,
          corridorSize: 5,
          view: "north",
          position: "left",
        },
        { roomNo: "460715", view: "west-south", position: "right" },
        { roomNo: "460716", view: "east-north", position: "left" },
        { roomNo: "460717", view: "west-south", position: "right" },
        { roomNo: "460718", view: "east-north", position: "left" },
        { roomNo: "460719", view: "west-south", position: "right" },
        { roomNo: "460720", view: "east-north", position: "left" },
        { roomNo: "460721", view: "west-south", position: "right" },
        { roomNo: "460722", view: "east-north", position: "left" },
        { roomNo: "460723", view: "west-south", position: "right" },
      ],
    },
    노스동: {
      "1F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430101", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430103", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430105", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430107", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430109", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430112",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430111", view: "east-north", position: "right" },
        { roomNo: "430114", view: "west-south", position: "left" },
        { roomNo: "430113", view: "east-north", position: "right" },
        { roomNo: "430116", view: "west-south", position: "left" },
        { roomNo: "430115", view: "east-north", position: "right" },
        { roomNo: "430118", view: "west-south", position: "left" },
        { roomNo: "430117", view: "east-north", position: "right" },
        { roomNo: "430119", isCenter: true },
      ],
      "2F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430201", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430203", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430205", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430207", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430209", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430212",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430211", view: "east-north", position: "right" },
        { roomNo: "430214", view: "west-south", position: "left" },
        { roomNo: "430213", view: "east-north", position: "right" },
        { roomNo: "430216", view: "west-south", position: "left" },
        { roomNo: "430215", view: "east-north", position: "right" },
        { roomNo: "430218", view: "west-south", position: "left" },
        { roomNo: "430217", view: "east-north", position: "right" },
        { roomNo: "430219", isCenter: true },
      ],
      "3F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430301", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430303", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430305", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430307", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430309", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430312",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430311", view: "east-north", position: "right" },
        { roomNo: "430314", view: "west-south", position: "left" },
        { roomNo: "430313", view: "east-north", position: "right" },
        { roomNo: "430316", view: "west-south", position: "left" },
        { roomNo: "430315", view: "east-north", position: "right" },
        { roomNo: "430318", view: "west-south", position: "left" },
        { roomNo: "430317", view: "east-north", position: "right" },
        { roomNo: "430319", isCenter: true },
      ],
      "4F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430401", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430403", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430405", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430407", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430409", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430412",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430411", view: "east-north", position: "right" },
        { roomNo: "430414", view: "west-south", position: "left" },
        { roomNo: "430413", view: "east-north", position: "right" },
        { roomNo: "430416", view: "west-south", position: "left" },
        { roomNo: "430415", view: "east-north", position: "right" },
        { roomNo: "430418", view: "west-south", position: "left" },
        { roomNo: "430417", view: "east-north", position: "right" },
        { roomNo: "430419", isCenter: true },
      ],
      "5F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430501", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430503", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430505", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430507", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430509", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430512",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430511", view: "east-north", position: "right" },
        { roomNo: "430514", view: "west-south", position: "left" },
        { roomNo: "430513", view: "east-north", position: "right" },
        { roomNo: "430516", view: "west-south", position: "left" },
        { roomNo: "430515", view: "east-north", position: "right" },
        { roomNo: "430518", view: "west-south", position: "left" },
        { roomNo: "430517", view: "east-north", position: "right" },
      ],
      "6F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430601", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430603", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430605", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430607", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430609", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430612",
          corridorStart: 7,
          corridorSize: 4,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430611", view: "east-north", position: "right" },
        { roomNo: "430614", view: "west-south", position: "left" },
        { roomNo: "430613", view: "east-north", position: "right" },
        { roomNo: "430616", view: "west-south", position: "left" },
        { roomNo: "430615", view: "east-north", position: "right" },
        { roomNo: "430618", view: "west-south", position: "left" },
        { roomNo: "430617", view: "east-north", position: "right" },
      ],
      "7F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 5,
          view: "east-south",
        },
        { roomNo: "430701", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430703", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430705", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430707", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430709", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430712",
          corridorStart: 7,
          corridorSize: 3,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430711", view: "east-north", position: "right" },
        { roomNo: "430714", view: "west-south", position: "left" },
        { roomNo: "430713", view: "east-north", position: "right" },
        { roomNo: "430716", view: "west-south", position: "left" },
        { roomNo: "430715", view: "east-north", position: "right" },
      ],
      "8F": [
        {
          roomNo: "",
          isEmpty: true,
          corridorStart: 1,
          corridorSize: 3,
          view: "east-south",
        },
        { roomNo: "430805", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430807", view: "east-south", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "430809", view: "east-south", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "430812",
          corridorStart: 5,
          corridorSize: 3,
          view: "west-south",
          position: "left",
        },
        { roomNo: "430811", view: "east-north", position: "right" },
        { roomNo: "430814", view: "west-south", position: "left" },
        { roomNo: "430813", view: "east-north", position: "right" },
        { roomNo: "430816", view: "west-south", position: "left" },
        { roomNo: "430815", view: "east-north", position: "right" },
      ],
    },
  },
  힐스빌리지: {
    C동: {
      "3F": [
        {
          roomNo: "210319",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210320", view: "north", position: "right" },
        { roomNo: "210317", view: "south", position: "left" },
        { roomNo: "210318", view: "north", position: "right" },
        { roomNo: "210315", view: "south", position: "left" },
        { roomNo: "210316", view: "north", position: "right" },
        { roomNo: "210313", view: "south", position: "left" },
        { roomNo: "210314", view: "north", position: "right" },
        { roomNo: "210311", view: "south", position: "left" },
        { roomNo: "210312", view: "north", position: "right" },
        { roomNo: "210309", view: "south", position: "left" },
        { roomNo: "210310", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210301",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210302", view: "west-north", position: "right" },
        { roomNo: "210303", view: "east-south", position: "left" },
        { roomNo: "210304", view: "west-north", position: "right" },
        { roomNo: "210305", view: "east-south", position: "left" },
        { roomNo: "210306", view: "west-north", position: "right" },
        { roomNo: "210307", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210308", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "4F": [
        {
          roomNo: "210419",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210420", view: "north", position: "right" },
        { roomNo: "210417", view: "south", position: "left" },
        { roomNo: "210418", view: "north", position: "right" },
        { roomNo: "210415", view: "south", position: "left" },
        { roomNo: "210416", view: "north", position: "right" },
        { roomNo: "210413", view: "south", position: "left" },
        { roomNo: "210414", view: "north", position: "right" },
        { roomNo: "210411", view: "south", position: "left" },
        { roomNo: "210412", view: "north", position: "right" },
        { roomNo: "210409", view: "south", position: "left" },
        { roomNo: "210410", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210401",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210402", view: "west-north", position: "right" },
        { roomNo: "210403", view: "east-south", position: "left" },
        { roomNo: "210404", view: "west-north", position: "right" },
        { roomNo: "210405", view: "east-south", position: "left" },
        { roomNo: "210406", view: "west-north", position: "right" },
        { roomNo: "210407", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210408", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "5F": [
        {
          roomNo: "210519",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210520", view: "north", position: "right" },
        { roomNo: "210517", view: "south", position: "left" },
        { roomNo: "210518", view: "north", position: "right" },
        { roomNo: "210515", view: "south", position: "left" },
        { roomNo: "210516", view: "north", position: "right" },
        { roomNo: "210513", view: "south", position: "left" },
        { roomNo: "210514", view: "north", position: "right" },
        { roomNo: "210511", view: "south", position: "left" },
        { roomNo: "210512", view: "north", position: "right" },
        { roomNo: "210509", view: "south", position: "left" },
        { roomNo: "210510", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210501",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210502", view: "west-north", position: "right" },
        { roomNo: "210503", view: "east-south", position: "left" },
        { roomNo: "210504", view: "west-north", position: "right" },
        { roomNo: "210505", view: "east-south", position: "left" },
        { roomNo: "210506", view: "west-north", position: "right" },
        { roomNo: "210507", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210508", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "6F": [
        {
          roomNo: "210619",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210620", view: "north", position: "right" },
        { roomNo: "210617", view: "south", position: "left" },
        { roomNo: "210618", view: "north", position: "right" },
        { roomNo: "210615", view: "south", position: "left" },
        { roomNo: "210616", view: "north", position: "right" },
        { roomNo: "210613", view: "south", position: "left" },
        { roomNo: "210614", view: "north", position: "right" },
        { roomNo: "210611", view: "south", position: "left" },
        { roomNo: "210612", view: "north", position: "right" },
        { roomNo: "210609", view: "south", position: "left" },
        { roomNo: "210610", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210601",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210602", view: "west-north", position: "right" },
        { roomNo: "210603", view: "east-south", position: "left" },
        { roomNo: "210604", view: "west-north", position: "right" },
        { roomNo: "210605", view: "east-south", position: "left" },
        { roomNo: "210606", view: "west-north", position: "right" },
        { roomNo: "210607", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210608", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "7F": [
        {
          roomNo: "210719",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210720", view: "north", position: "right" },
        { roomNo: "210717", view: "south", position: "left" },
        { roomNo: "210718", view: "north", position: "right" },
        { roomNo: "210715", view: "south", position: "left" },
        { roomNo: "210716", view: "north", position: "right" },
        { roomNo: "210713", view: "south", position: "left" },
        { roomNo: "210714", view: "north", position: "right" },
        { roomNo: "210711", view: "south", position: "left" },
        { roomNo: "210712", view: "north", position: "right" },
        { roomNo: "210709", view: "south", position: "left" },
        { roomNo: "210710", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210701",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210702", view: "west-north", position: "right" },
        { roomNo: "210703", view: "east-south", position: "left" },
        { roomNo: "210704", view: "west-north", position: "right" },
        { roomNo: "210705", view: "east-south", position: "left" },
        { roomNo: "210706", view: "west-north", position: "right" },
        { roomNo: "210707", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210708", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "8F": [
        {
          roomNo: "210819",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210820", view: "north", position: "right" },
        { roomNo: "210817", view: "south", position: "left" },
        { roomNo: "210818", view: "north", position: "right" },
        { roomNo: "210815", view: "south", position: "left" },
        { roomNo: "210816", view: "north", position: "right" },
        { roomNo: "210813", view: "south", position: "left" },
        { roomNo: "210814", view: "north", position: "right" },
        { roomNo: "210811", view: "south", position: "left" },
        { roomNo: "210812", view: "north", position: "right" },
        { roomNo: "210809", view: "south", position: "left" },
        { roomNo: "210810", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210801",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210802", view: "west-north", position: "right" },
        { roomNo: "210803", view: "east-south", position: "left" },
        { roomNo: "210804", view: "west-north", position: "right" },
        { roomNo: "210805", view: "east-south", position: "left" },
        { roomNo: "210806", view: "west-north", position: "right" },
        { roomNo: "210807", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210808", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "9F": [
        {
          roomNo: "210919",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "210920", view: "north", position: "right" },
        { roomNo: "210917", view: "south", position: "left" },
        { roomNo: "210918", view: "north", position: "right" },
        { roomNo: "210915", view: "south", position: "left" },
        { roomNo: "210916", view: "north", position: "right" },
        { roomNo: "210913", view: "south", position: "left" },
        { roomNo: "210914", view: "north", position: "right" },
        { roomNo: "210911", view: "south", position: "left" },
        { roomNo: "210912", view: "north", position: "right" },
        { roomNo: "210909", view: "south", position: "left" },
        { roomNo: "210910", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "210901",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "210902", view: "west-north", position: "right" },
        { roomNo: "210903", view: "east-south", position: "left" },
        { roomNo: "210904", view: "west-north", position: "right" },
        { roomNo: "210905", view: "east-south", position: "left" },
        { roomNo: "210906", view: "west-north", position: "right" },
        { roomNo: "210907", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "210908", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "10F": [
        {
          roomNo: "211019",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "211020", view: "north", position: "right" },
        { roomNo: "211017", view: "south", position: "left" },
        { roomNo: "211018", view: "north", position: "right" },
        { roomNo: "211015", view: "south", position: "left" },
        { roomNo: "211016", view: "north", position: "right" },
        { roomNo: "211013", view: "south", position: "left" },
        { roomNo: "211014", view: "north", position: "right" },
        { roomNo: "211011", view: "south", position: "left" },
        { roomNo: "211012", view: "north", position: "right" },
        { roomNo: "211009", view: "south", position: "left" },
        { roomNo: "211010", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "211001",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211002", view: "west-north", position: "right" },
        { roomNo: "211003", view: "east-south", position: "left" },
        { roomNo: "211004", view: "west-north", position: "right" },
        { roomNo: "211005", view: "east-south", position: "left" },
        { roomNo: "211006", view: "west-north", position: "right" },
        { roomNo: "211007", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "211008", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "11F": [
        {
          roomNo: "211119",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "211120", view: "north", position: "right" },
        { roomNo: "211117", view: "south", position: "left" },
        { roomNo: "211118", view: "north", position: "right" },
        { roomNo: "211115", view: "south", position: "left" },
        { roomNo: "211116", view: "north", position: "right" },
        { roomNo: "211113", view: "south", position: "left" },
        { roomNo: "211114", view: "north", position: "right" },
        { roomNo: "211111", view: "south", position: "left" },
        { roomNo: "211112", view: "north", position: "right" },
        { roomNo: "211109", view: "south", position: "left" },
        { roomNo: "211110", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "211101",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211102", view: "west-north", position: "right" },
        { roomNo: "211103", view: "east-south", position: "left" },
        { roomNo: "211104", view: "west-north", position: "right" },
        { roomNo: "211105", view: "east-south", position: "left" },
        { roomNo: "211106", view: "west-north", position: "right" },
        { roomNo: "211107", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "211108", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "12F": [
        {
          roomNo: "211219",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "211220", view: "north", position: "right" },
        { roomNo: "211217", view: "south", position: "left" },
        { roomNo: "211218", view: "north", position: "right" },
        { roomNo: "211215", view: "south", position: "left" },
        { roomNo: "211216", view: "north", position: "right" },
        { roomNo: "211213", view: "south", position: "left" },
        { roomNo: "211214", view: "north", position: "right" },
        { roomNo: "211211", view: "south", position: "left" },
        { roomNo: "211212", view: "north", position: "right" },
        { roomNo: "211209", view: "south", position: "left" },
        { roomNo: "211210", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "211201",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211202", view: "west-north", position: "right" },
        { roomNo: "211203", view: "east-south", position: "left" },
        { roomNo: "211204", view: "west-north", position: "right" },
        { roomNo: "211205", view: "east-south", position: "left" },
        { roomNo: "211206", view: "west-north", position: "right" },
        { roomNo: "211207", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "211208", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "13F": [
        {
          roomNo: "211319",
          corridorStart: 1,
          corridorSize: 6,
          view: "south",
          position: "left",
        },
        { roomNo: "211320", view: "north", position: "right" },
        { roomNo: "211317", view: "south", position: "left" },
        { roomNo: "211318", view: "north", position: "right" },
        { roomNo: "211315", view: "south", position: "left" },
        { roomNo: "211316", view: "north", position: "right" },
        { roomNo: "211313", view: "south", position: "left" },
        { roomNo: "211314", view: "north", position: "right" },
        { roomNo: "211311", view: "south", position: "left" },
        { roomNo: "211312", view: "north", position: "right" },
        { roomNo: "211309", view: "south", position: "left" },
        { roomNo: "211310", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "211301",
          corridorStart: 8,
          corridorSize: 5,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211302", view: "west-north", position: "right" },
        { roomNo: "211303", view: "east-south", position: "left" },
        { roomNo: "211304", view: "west-north", position: "right" },
        { roomNo: "211305", view: "east-south", position: "left" },
        { roomNo: "211306", view: "west-north", position: "right" },
        { roomNo: "211307", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
        { roomNo: "211308", view: "east-south", position: "left" },
        { roomNo: "", isEmpty: true },
      ],
      "14F": [
        {
          roomNo: "211415",
          corridorStart: 1,
          corridorSize: 4,
          view: "south",
          position: "left",
        },
        { roomNo: "211416", view: "north", position: "right" },
        { roomNo: "211413", view: "south", position: "left" },
        { roomNo: "211414", view: "north", position: "right" },
        { roomNo: "211411", view: "south", position: "left" },
        { roomNo: "211412", view: "north", position: "right" },
        { roomNo: "211409", view: "south", position: "left" },
        { roomNo: "211410", view: "north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "211401",
          corridorStart: 6,
          corridorSize: 3,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211402", view: "west-north", position: "right" },
        { roomNo: "211403", view: "east-south", position: "left" },
        { roomNo: "211404", view: "west-north", position: "right" },
        { roomNo: "211405", view: "east-south", position: "left" },
        { roomNo: "211406", view: "west-north", position: "right" },
      ],
      "15F": [
        { roomNo: "elevator" },
        {
          roomNo: "211501",
          corridorStart: 2,
          corridorSize: 3,
          view: "east-south",
          position: "left",
        },
        { roomNo: "211502", view: "west-north", position: "right" },
        { roomNo: "211503", view: "east-south", position: "left" },
        { roomNo: "211504", view: "west-north", position: "right" },
        { roomNo: "211505", view: "east-south", position: "left" },
        { roomNo: "211506", view: "west-north", position: "right" },
      ],
    },
    D동: {
      "3F": [
        {
          roomNo: "220301",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220302", view: "west", position: "right" },
        { roomNo: "220303", view: "east", position: "left" },
        { roomNo: "220304", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220306", view: "west", position: "right" },
        { roomNo: "220307", view: "east-south", position: "left" },
        { roomNo: "220308", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220309",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220310", view: "west-north", position: "right" },
        { roomNo: "220311", view: "east-south", position: "left" },
        { roomNo: "220312", view: "west-north", position: "right" },
        { roomNo: "220313", view: "east-south", position: "left" },
        { roomNo: "220314", view: "west-north", position: "right" },
        { roomNo: "220315", view: "east-south", position: "left" },
        { roomNo: "220316", view: "west-north", position: "right" },
      ],
      "4F": [
        {
          roomNo: "220401",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220402", view: "west", position: "right" },
        { roomNo: "220403", view: "east", position: "left" },
        { roomNo: "220404", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220406", view: "west", position: "right" },
        { roomNo: "220407", view: "east-south", position: "left" },
        { roomNo: "220408", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220409",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220410", view: "west-north", position: "right" },
        { roomNo: "220411", view: "east-south", position: "left" },
        { roomNo: "220412", view: "west-north", position: "right" },
        { roomNo: "220413", view: "east-south", position: "left" },
        { roomNo: "220414", view: "west-north", position: "right" },
        { roomNo: "220415", view: "east-south", position: "left" },
        { roomNo: "220416", view: "west-north", position: "right" },
      ],
      "5F": [
        {
          roomNo: "220501",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220502", view: "west", position: "right" },
        { roomNo: "220503", view: "east", position: "left" },
        { roomNo: "220504", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220506", view: "west", position: "right" },
        { roomNo: "220507", view: "east-south", position: "left" },
        { roomNo: "220508", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220509",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220510", view: "west-north", position: "right" },
        { roomNo: "220511", view: "east-south", position: "left" },
        { roomNo: "220512", view: "west-north", position: "right" },
        { roomNo: "220513", view: "east-south", position: "left" },
        { roomNo: "220514", view: "west-north", position: "right" },
        { roomNo: "220515", view: "east-south", position: "left" },
        { roomNo: "220516", view: "west-north", position: "right" },
      ],
      "6F": [
        {
          roomNo: "220601",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220602", view: "west", position: "right" },
        { roomNo: "220603", view: "east", position: "left" },
        { roomNo: "220604", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220606", view: "west", position: "right" },
        { roomNo: "220607", view: "east-south", position: "left" },
        { roomNo: "220608", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220609",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220610", view: "west-north", position: "right" },
        { roomNo: "220611", view: "east-south", position: "left" },
        { roomNo: "220612", view: "west-north", position: "right" },
        { roomNo: "220613", view: "east-south", position: "left" },
        { roomNo: "220614", view: "west-north", position: "right" },
        { roomNo: "220615", view: "east-south", position: "left" },
        { roomNo: "220616", view: "west-north", position: "right" },
      ],
      "7F": [
        {
          roomNo: "220701",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220702", view: "west", position: "right" },
        { roomNo: "220703", view: "east", position: "left" },
        { roomNo: "220704", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220706", view: "west", position: "right" },
        { roomNo: "220707", view: "east-south", position: "left" },
        { roomNo: "220708", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220709",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220710", view: "west-north", position: "right" },
        { roomNo: "220711", view: "east-south", position: "left" },
        { roomNo: "220712", view: "west-north", position: "right" },
        { roomNo: "220713", view: "east-south", position: "left" },
        { roomNo: "220714", view: "west-north", position: "right" },
        { roomNo: "220715", view: "east-south", position: "left" },
        { roomNo: "220716", view: "west-north", position: "right" },
      ],
      "8F": [
        {
          roomNo: "220801",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220802", view: "west", position: "right" },
        { roomNo: "220803", view: "east", position: "left" },
        { roomNo: "220804", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220806", view: "west", position: "right" },
        { roomNo: "220807", view: "east-south", position: "left" },
        { roomNo: "220808", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220809",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220810", view: "west-north", position: "right" },
        { roomNo: "220811", view: "east-south", position: "left" },
        { roomNo: "220812", view: "west-north", position: "right" },
        { roomNo: "220813", view: "east-south", position: "left" },
        { roomNo: "220814", view: "west-north", position: "right" },
        { roomNo: "220815", view: "east-south", position: "left" },
        { roomNo: "220816", view: "west-north", position: "right" },
      ],
      "9F": [
        {
          roomNo: "220901",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "220902", view: "west", position: "right" },
        { roomNo: "220903", view: "east", position: "left" },
        { roomNo: "220904", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "220906", view: "west", position: "right" },
        { roomNo: "220907", view: "east-south", position: "left" },
        { roomNo: "220908", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "220909",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "220910", view: "west-north", position: "right" },
        { roomNo: "220911", view: "east-south", position: "left" },
        { roomNo: "220912", view: "west-north", position: "right" },
        { roomNo: "220913", view: "east-south", position: "left" },
        { roomNo: "220914", view: "west-north", position: "right" },
        { roomNo: "220915", view: "east-south", position: "left" },
        { roomNo: "220916", view: "west-north", position: "right" },
      ],
      "10F": [
        {
          roomNo: "221001",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "221002", view: "west", position: "right" },
        { roomNo: "221003", view: "east", position: "left" },
        { roomNo: "221004", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "221006", view: "west", position: "right" },
        { roomNo: "221007", view: "east-south", position: "left" },
        { roomNo: "221008", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "221009",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "221010", view: "west-north", position: "right" },
        { roomNo: "221011", view: "east-south", position: "left" },
        { roomNo: "221012", view: "west-north", position: "right" },
        { roomNo: "221013", view: "east-south", position: "left" },
        { roomNo: "221014", view: "west-north", position: "right" },
        { roomNo: "221015", view: "east-south", position: "left" },
        { roomNo: "221016", view: "west-north", position: "right" },
      ],
      "11F": [
        {
          roomNo: "221101",
          corridorStart: 1,
          corridorSize: 4,
          view: "east",
          position: "left",
        },
        { roomNo: "221102", view: "west", position: "right" },
        { roomNo: "221103", view: "east", position: "left" },
        { roomNo: "221104", view: "west", position: "right" },
        { roomNo: "", isEmpty: true },
        { roomNo: "221106", view: "west", position: "right" },
        { roomNo: "221107", view: "east-south", position: "left" },
        { roomNo: "221108", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "221109",
          corridorStart: 6,
          corridorSize: 4,
          view: "east-south",
          position: "left",
        },
        { roomNo: "221110", view: "west-north", position: "right" },
        { roomNo: "221111", view: "east-south", position: "left" },
        { roomNo: "221112", view: "west-north", position: "right" },
        { roomNo: "221113", view: "east-south", position: "left" },
        { roomNo: "221114", view: "west-north", position: "right" },
        { roomNo: "221115", view: "east-south", position: "left" },
        { roomNo: "221116", view: "west-north", position: "right" },
      ],
      "12F": [
        {
          roomNo: "221200",
          corridorStart: 1,
          corridorSize: 2,
          isHiddenCorridorText: true,
          customStyle: {
            gridArea: "1 / 1 / span 1 / span 3",
            height: "60px",
            lineHeight: "56px",
          },
        },
        { roomNo: "221207", view: "east-south", position: "left" },
        { roomNo: "221208", view: "west-north", position: "right" },
        { roomNo: "elevator" },
        {
          roomNo: "221209",
          corridorStart: 4,
          corridorSize: 6,
          view: "east-south",
          position: "left-middle",
          customStyle: {
            gridArea: "4 / 1 / span 3 / span 1",
            width: "100%",
            height: "60px",
            lineHeight: "56px",
          },
        },
        { roomNo: "221210", view: "west-north", position: "right" },
        { roomNo: "" },
        { roomNo: "221212", view: "west-north", position: "right" },
        {
          roomNo: "221213",
          view: "east-south",
          position: "left-middle",
          customStyle: {
            gridArea: "7 / 1 / span 3 / span 1",
            width: "100%",
            height: "60px",
            lineHeight: "56px",
          },
        },
        { roomNo: "221214", view: "west-north", position: "right" },
        { roomNo: "" },
        { roomNo: "221216", view: "west-north", position: "right" },
      ],
    },
  },
};
