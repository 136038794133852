import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_SANHA_BACKEND_BASEURL;
const apiKey = '$2a$12$74i/La/q3cf1ex7tQVKHZ.DPzwADixYluOVOcJMEZ4ukPvdlRKery';
const vendorId = 'HDCRESORT';
const systemId = 'WINGSHDCRESORT';
const langTypeCode = 'KOR';

// config
const request = axios.create({
  baseURL,
  timeout: 30000,
});

const headers = (header) => {
  return {
    headers: {
      'API-KEY': apiKey,
      'VENDOR_ID': vendorId,
      'Content-Type': 'application/json',
      ...header && header,
    }
  };
};

const querystring = (query) => {
  return {
    params: query,
  };
};

export const listBooking = ({rsvnNo, rsvnSeqNo}) => {
  return request.get(`/api/V1/reservations/${rsvnNo}/${rsvnSeqNo}`, {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const selectCheckedInBooking = ({bsnsCode, propertyNo, folioNo}) => {
  return request.get(`/api/V1/${systemId}/inhouse/${bsnsCode}/${propertyNo}/${folioNo}`, {
    ...headers(),
  });
};

export const confirmCheckInPayment = ({rsvnNo, rsvnSeqNo}) => {
  return request.get(`/api/V1/payments/pg/reservation/${rsvnNo}/${rsvnSeqNo}`, {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const confirmCheckInAmount = ({rsvnNo, rsvnSeqNo}) => {
  return request.get(`/api/V1/reservations/${rsvnNo}/${rsvnSeqNo}/amount`, {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const registCheckInPayment = ({trnsNo, intApprTypeCode, intPayStatusCode, payFnshDate, successYn, trnsDate, trnsHHMMSS, trnsAmt, cardMonthCnt, cardApprNo, cardName, cardIssueCode, cardPurcCode, trnsBankCode, acctNo, depositor, cashRcptTypeCode, cashRcptApptNo, cashRcptCxlApptNo, responseCode, message1, message2, dpsitPayDivCode, userId, userIp, rsvnNo, rsvnSeqNo, payMgtNo, dpsitPayNo, outYN, outMsg}) => {
  return request.post(`/api/V1/payments/pg/reservation/${rsvnNo}/${rsvnSeqNo}`,
    {
      systemId,
      vendorId,
      trnsNo,                // 거래 승인 번호
      intApprTypeCode,       // 인터넷 결재 유형 코드 (P)
      intPayStatusCode,      // 인터넷 결재 상태 코드 (11)
      payFnshDate,           // 인터넷 지불 일자
      successYn,
      trnsDate,              // 거래일자 (YYYYMMDD 포멧)
      trnsHHMMSS,
      trnsAmt,
      cardMonthCnt,          // 카드 할부 개월 수
      cardApprNo,            // 카드 승인 번호
      cardName,
      cardIssueCode,         // 카드 발급사 코드
      cardPurcCode,          // 카드 매입사 코드
      trnsBankCode,          // 거래 은행 코드
      acctNo,                // 계좌 번호
      depositor,             // 예금주 명
      cashRcptTypeCode,      // 현금 영수증 발급 구분 코드
      cashRcptApptNo,        // 현금 영수증 승인 번호
      cashRcptCxlApptNo,     // 현금 영수증 취소 승인 번호
      responseCode,
      message1,
      message2,
      rsvnNo,
      rsvnSeqNo,
      dpsitPayDivCode,       // 선수금 분류 코드
      userId,
      userIp,
      langTypeCode,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg,
    },
    headers(),
  );
};

export const listAvailableRoom = ({rsvnNo, rsvnSeqNo}) => {
  return request.get('/api/V1/rooms/available', {
    ...headers(),
    ...querystring({
      rsvnNo,
      rsvnSeqNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const checkIn = ({rsvnNo, rsvnSeqNo, roomNo}) => {
  return request.post('/api/V1/checkins',
    {
      rsvnNo,
      rsvnSeqNo,
      systemId,
      roomNo,
      langTypeCode,
    },
    headers(),
  );
};

export const confirmCheckOutAmount = ({folioNo}) => {
  return request.get(`/api/V1/checkouts/${folioNo}/balance`, {
    ...headers(),
    ...querystring({
      folioNo,
      systemId,
      vendorId,
      langTypeCode,
    }),
  });
};

export const registCheckOutPayment = ({trnsNo, intApprTypeCode, intPayStatusCode, payFnshDate, successYn, trnsDate, trnsHHMMSS, trnsAmt, cardMonthCnt, cardApprNo, cardName, cardIssueCode, cardPurcCode, trnsBankCode, acctNo, depositor, cashRcptTypeCode, cashRcptApptNo, cashRcptCxlApptNo, responseCode, message1, message2, folioNo, userId, userIp, dpsitPayDivCode, payMgtNo, dpsitPayNo, outYN, outMsg}) => {
  return request.post(`/api/V1/payment/pg/checkout/${folioNo}`,
    {
      systemId,
      vendorId,
      trnsNo,                // 거래 승인 번호
      intApprTypeCode,       // 인터넷 결재 유형 코드 (P)
      intPayStatusCode,      // 인터넷 결재 상태 코드 (11)
      payFnshDate,           // 인터넷 지불 일자
      successYn,
      trnsDate,              // 거래일자 (YYYYMMDD 포멧)
      trnsHHMMSS,
      trnsAmt,
      cardMonthCnt,          // 카드 할부 개월 수
      cardApprNo,            // 카드 승인 번호
      cardName,
      cardIssueCode,         // 카드 발급사 코드
      cardPurcCode,          // 카드 매입사 코드
      trnsBankCode,          // 거래 은행 코드
      acctNo,                // 계좌 번호
      depositor,             // 예금주 명
      cashRcptTypeCode,      // 현금 영수증 발급 구분 코드
      cashRcptApptNo,        // 현금 영수증 승인 번호
      cashRcptCxlApptNo,     // 현금 영수증 취소 승인 번호
      responseCode,
      message1,
      message2,
      folioNo,
      dpsitPayDivCode,       // 선수금 분류 코드
      userId,
      userIp,
      langTypeCode,
      payMgtNo,
      dpsitPayNo,
      outYN,
      outMsg,
    },
    headers(),
  );
};

export const checkOut = ({folioNo, earlyCheckoutYN}) => {
  return request.post('/api/V1/checkouts',
    {
      folioNo,
      earlyCheckoutYN,
      systemId,
      vendorId,
      langTypeCode,
    },
    headers(),
  );
};