import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_IMGATE_BACKEND_BASEURL;

// config
const request = axios.create({
  baseURL,
});

const querystring = (query) => {
  return {
    params: query,
  };
};

export const encryptData = ({EdiDate, Amt}) => {
  return request.get('multifamily/users/oakvalley/payments/encrypt_data', {
    ...querystring({
      EdiDate,
      Amt,
    }),
  });
};
