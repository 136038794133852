import React, { Fragment, useEffect } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import _ from 'lodash';
import Modal from '../Modal';

const CheckInConfirm = ({
  isOpen,
  selectedRoom,
  onClose,
  checkIn,
}) => {
  const debounceCheckIn = _.debounce(() => checkIn(), 800, { maxWait: 1000 });

  const onClickCheckIn = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_ok',
    });
    debounceCheckIn();
  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkin_confirm_cancel',
    });
    onClose();
  };

  const roomNoFormatter = (roomNo) => {
    return roomNo ? parseInt(roomNo.slice(roomNo.length - 4)) : roomNo;
  };

  const subZoneTypeNameFormatter = (subZoneTypeCode) => {
    return {
      east: '동관',
      west: '서관',
    }[subZoneTypeCode] || '';
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkin_confirm',
    });
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='check-in-confirm-title'>
              체크인 하시겠습니까?
            </span>
            <span className='check-in-confirm-description'>
              선택하신 객실 정보를 확인해주세요.
            </span>
            <div className='room-info-container'>
              <span className='room-info-description'>
                {`${selectedRoom.propertyName} ${selectedRoom.zoneTypeName} ${subZoneTypeNameFormatter(selectedRoom.subZoneTypeCode)}`}<br/>
                {`${selectedRoom.floorName} ${roomNoFormatter(selectedRoom.roomNo)}`}
              </span>
            </div>
            <div className='check-in-confirm-button-container'>
              <div className='cancel-button' onClick={onCancel}>
                <span className='cancel-button-text'>
                  아니요
                </span>
              </div>
              <div className='check-in-request-button' onClick={onClickCheckIn}>
                <span className='check-in-request-button-text c-sky-blue'>
                  체크인 하기
                </span>
              </div>
            </div>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default CheckInConfirm;