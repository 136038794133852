import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons';

const PaymentLoading = ({
  history,
}) => {
  return (
    <Fragment>
      <div className='payment-response-icon'>
        <FontAwesomeIcon icon={faHourglassStart} />
      </div>
      <span className='payment-response-title'>
        결제가 진행중 입니다.
      </span>
      <span className='payment-response-description'>
        잠시만 기다려 주세요.
      </span>
    </Fragment>
  );
};

export default PaymentLoading;