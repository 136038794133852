import BookingList from '../containers/BookingListContainer';
import TermsOfUseList from '../containers/TermsOfUseListContainer';
import CheckIn from '../containers/CheckInContainer';
import CheckInPayment from '../containers/CheckInPaymentContainer';
import CheckInPaymentResponse from '../containers/CheckInPaymentResponseContainer';
import CheckOutPayment from '../containers/CheckOutPaymentContainer';
import CheckOutPaymentResponse from '../containers/CheckOutPaymentResponseContainer';
import CheckOutPaymentSuccess from '../containers/CheckOutPaymentSuccessContainer';
import RoomSelect from '../containers/RoomSelectContainer';
import Page404 from '../components/views/Page404';

const mainRoutes = {
  path: '/',
  name: 'Booking List',
  component: BookingList,
};

const termsOfUseListRoutes = {
  path: '/terms_of_use',
  name: 'Terms of Use List',
  component: TermsOfUseList,
};

const checkInRoutes = {
  path: '/checkin',
  name: 'Check In',
  component: CheckIn,
};

const checkInPaymentRoutes = {
  path: '/checkin/payment',
  name: 'Check In Payment',
  component: CheckInPayment,
};

const checkInPaymentResponseRoutes = {
  path: '/checkin/payment/response',
  name: 'Check In Payment Response',
  component: CheckInPaymentResponse,
};

const checkOutPaymentRoutes = {
  path: '/checkout/payment',
  name: 'Check Out Payment',
  component: CheckOutPayment,
};

const checkOutPaymentResponseRoutes = {
  path: '/checkout/payment/response',
  name: 'Check Out Payment Response',
  component: CheckOutPaymentResponse,
};

const checkOutPaymentSuccessRoutes = {
  path: '/checkout/payment/success',
  name: 'Check Out Payment Success',
  component: CheckOutPaymentSuccess,
};

const roomSelectRoutes = {
  path: '/room',
  name: 'Room Select',
  component: RoomSelect,
};

const page404Routes = {
  path: '*',
  name: '404 Page',
  component: Page404,
};

export const routesList = [
  mainRoutes,
  termsOfUseListRoutes,
  checkInRoutes,
  checkInPaymentRoutes,
  checkInPaymentResponseRoutes,
  checkOutPaymentRoutes,
  checkOutPaymentResponseRoutes,
  checkOutPaymentSuccessRoutes,
  roomSelectRoutes,
  page404Routes,
];